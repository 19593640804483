import { ref } from 'vue'
import axiosClient from '@/utils/axios'

const useNewsApi = () => {
  const errors = ref(null)

  // INDEX
  const index = async (page, filters = {}) => {
    let filterParams = filtersBuilder(filters.value)
    errors.value = null
    try {
      const res = await axiosClient.get(`api/actualites?page=${page.value}`, {
        params: filterParams,
      })
      return res.data
    } catch (err) {
      catchErrors(err)
      throw new Error(err.response ? err.response.data.message : err.message)
    }
  }

  // SHOW
  const show = async (slug) => {
    errors.value = null
    try {
      const res = await axiosClient.get('api/actualites/' + slug.value)
      return res.data
    } catch (err) {
      catchErrors(err)
      throw new Error(err.response ? err.response.data.message : err.message)
    }
  }

  // SHOW
  const incrementViews = async (id) => {
    errors.value = null
    try {
      const res = await axiosClient.post('api/actualites/increment/' + id)
      return res.data
    } catch (err) {
      catchErrors(err)
      throw new Error(err.response ? err.response.data.message : err.message)
    }
  }

  // ERROR HANDLER
  const catchErrors = (err) => {
    if (err.response && err.response.status === 422) {
      errors.value = err.response.data.errors || []
    } else if (err.response && err.response.status === 400) {
      errors.value = { message: err.response.data.errors || '' }
    } else if (err.response && err.response.status === 403) {
      errors.value = { message: err.response.data.error || '' }
    } else {
      errors.value = err.response ? err.response.statusText : err.message
    }
  }

  // FILTERS HANDLER
  const filtersBuilder = (payload) => {
    let filters = {}
    if (payload?.search && payload.search.length > 3)
      filters['search'] = payload.search
    if (payload?.date) filters['date'] = payload.date
    if (payload?.categories && payload.categories.length > 0)
      filters['categories'] = payload.categories
    if (payload?.tags && payload.tags.length > 0) filters['tags'] = payload.tags
    return filters
  }

  return {
    index,
    show,
    incrementViews,
    errors,
  }
}

export default useNewsApi
