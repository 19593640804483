<template>
    <contact-banner />
    <section class="md:p-0 p-4 relative z-20">
        <div class="bg-white overflow-hidden grid rounded-3xl shadow md:shadow-lg grid-cols-1 md:grid-cols-12 max-w-7xl mx-auto -mt-[20vh] l sm:-mt-[25vh] lg:-mt-[194px]">
            <div class="md:col-span-4 col-span-1 order-2 md:order-1 px-10 py-14 text-white bg-[url('~@/assets/team-bg.png')] bg-center bg-contain">
                <h2 class="text-3xl">Informations de contact</h2>
                <p class="my-4">Veuillez utiliser les coordonnées ci-dessous pour nous joindre. Notre équipe est disponible du lundi au vendredi.</p>
                <div class="contact-info-list">
                    <div class="contact-info-item">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="contact-icon" viewBox="0 0 16 16">
                                <path
                                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                            </svg>
                        </div>
                        <div class="contact-info">
                            <h3>Téléphone</h3>
                            <p>+261 38 92 542 07</p>
                        </div>
                    </div>
                    <div class="contact-info-item">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="contact-icon" viewBox="0 0 16 16">
                                <path
                                    d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                <path
                                    d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                            </svg>
                        </div>
                        <div class="contact-info">
                            <h3>Email</h3>
                            <p>contact@raph-co.com</p>
                        </div>
                    </div>
                    <div class="contact-info-item last">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="contact-icon" viewBox="0 0 16 16">
                                <path
                                    d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                            </svg>
                        </div>
                        <div class="contact-info">
                            <h3>Adresse</h3>
                            <p>Manakambahiny, Antananarivo</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:col-span-8 col-span-1 md:p-10 p-5 order-1 md:order-2">
                <contact-form />
            </div>
        </div>
        <google-map-card />
    </section>
</template>

<script setup>
import ContactBanner from './components/ContactBanner.vue';
import ContactForm from './components/ContactForm.vue';
import GoogleMapCard from './components/GoogleMapCard.vue';

import { useSeoMeta } from '@unhead/vue'

useSeoMeta({
    title: "Contact",
    description: "Contactez Raph and Co pour toutes vos questions sur nos services d'ingénierie à Madagascar. Notre équipe est là pour répondre à vos besoins avec des solutions personnalisées et une expertise locale en développement des talents d'ingénieurs.",
    keywords: "Raph and Co contact, ingénierie Madagascar, services d'ingénierie, solutions personnalisées, développement des talents d'ingénieurs, expertise locale, entreprises malgaches"
})

</script>

<style scoped>
.contact-info-list {
    @apply md:py-4
}

.contact-info-item {
    @apply mb-4 flex items-center md:gap-0 gap-3
}

.contact-info-item .icon {
    @apply md:h-20 w-14 md:w-20 h-14 flex items-center justify-center text-white !important
}
.contact-icon {
    @apply md:h-9 md:w-9 h-8 w-8 fill-white
}
.contact-info-item .contact-info h3 {
    @apply text-xl mb-2
}
</style>