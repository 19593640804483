import axios from "axios"

const baseURL = `${process.env.VUE_APP_API_URL}`

const axiosClient = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withXSRFToken: true,
    withCredentials: true
})

export default axiosClient