<template>
    <div class="animate-pulse bg-gray-100 rounded-3xl flex flex-col justify-between shadow">
        <div class="bg-gray-200 w-full h-[40vh] lg:max-h-[310px] rounded-t-3xl overflow-hidden"></div>
        <div class="p-5">
            <div class="h-6 bg-gray-300 rounded-full mb-2"></div>
            <div class="h-4 bg-gray-300 rounded-full w-3/4 mb-2"></div>
        </div>
        <div class="p-5 flex justify-between gap-3 items-center">
            <div class="flex gap-3 items-center">
                <div class="w-14 h-14 bg-gray-300 rounded-full"></div>
                <div class="h-4 bg-gray-300 rounded-full w-24"></div>
            </div>
            <div class="h-4 bg-gray-300 rounded-full w-24"></div>
        </div>
    </div>
</template>