import { VueQueryPlugin } from '@tanstack/vue-query'

const VueQuery = VueQueryPlugin
export const vueQueryGlobalOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
}
export default VueQuery
