<template>
    <div class="service-card">
        <div class="service-image">
            <img :src="props.image" :alt="props.title">
        </div>
        <div class="service-detail">
            <h2>{{ props.title }}</h2>
            <p>{{ props.description }}</p>
            <ul v-if="props.details" class="service-detail-list">
                <li v-for="(detail, i) in props.details" :key="i">
                    <svg xmlns="http://www.w3.org/2000/svg" class="list-icon" fill="currentColor" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <span>{{ detail }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps(['title', 'description', 'details', 'image'])
</script>

<style sscoped>
.service-card {
    @apply col-span-1 grid md:grid-cols-3 grid-cols-1 bg-white md:min-h-[400px] min-h-[50vh] shadow rounded-3xl overflow-hidden
}

.service-image {
    @apply col-span-1 bg-gray-100 overflow-hidden
}

.service-image img {
    @apply w-full md:h-full h-[30vh] object-cover brightness-90 duration-500 ease-in-out
}

.service-card:hover .service-image img {
    @apply scale-110
}

.service-detail {
    @apply md:col-span-2 col-span-1 p-6
}

.service-detail h2 {
    @apply text-2xl font-semibold text-gray-700 mb-3
}

.service-detail-list {
    @apply mt-4
}

.service-detail-list li {
    @apply flex items-center gap-3 py-1
}

.service-detail-list li .list-icon {
    @apply mt-1 h-6 w-6 text-blue-800
}
</style>