<template>
    <div class="flex items-center space-x-2">
        <!-- Previous Button -->
        <button :disabled="model <= 1" :class="{ 'pagination-nav': true, disabled: model <= 1 }" @click="prev">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                class="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
            </svg>
        </button>
        <!-- Page Numbers -->
        <button v-for="page in pages" :key="page" :class="{ 'page-item': true, active: model == page }"
            @click="setPage(page)">{{ page }}</button>
        <!-- Next Button -->
        <button :disabled="model >= props.length" :class="{ 'pagination-nav': true, disabled: model >= props.length }"
            @click="next">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                class="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
            </svg>
        </button>
    </div>
</template>

<script setup>
import { defineProps, defineModel, computed } from 'vue';
const model = defineModel({ required: true })
const props = defineProps({
    length: {
        type: [Number],
        required: true
    },
    totalVisible: {
        type: [Number],
        required: true
    }
})
const pages = computed(() => Array.from({ length: props.length }, (_, i) => i + 1))
const setPage = (page) => {
    model.value = page
}
const prev = () => {
    if (model.value > 1)
        model.value = model.value - 1
    else
        model.value = 1
}
const next = () => {
    if (model.value >= props.length)
        model.value = props.length
    else
        model.value = model.value + 1
}
</script>

<style scoped>
.page-item {
    @apply h-12 w-12 text-lg flex items-center justify-center  bg-white text-gray-700 rounded-full hover:bg-gray-200 shadow
}

.page-item.active {
    @apply bg-primary text-white
}

.pagination-nav {
    @apply h-12 w-12  flex items-center justify-center rounded-full hover:bg-gray-300
}

.pagination-nav.disabled {
    @apply pointer-events-none shadow-none
}
</style>