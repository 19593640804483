<template>
    <div class="custom-field">
        <input :type="props.type" :name="props.name" :class="{ error: props.errors?.[props.name] }" v-model="model"
            :placeholder="props.placeholder">
        <p class="error" v-if="props.errors?.[props.name]">{{ props.errors?.[props.name] }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineModel } from 'vue';
const model = defineModel({ required: true })
const props = defineProps({
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text',
      required: false
    },
    placeholder: {
      type: String,
      required: true
    },
    errors: {
      type: [Array, String, null, Object],
      required: true
    }
})
</script>

<style scoped>
.custom-field input {
  @apply block w-full px-6 py-3 focus:outline-none rounded-xl md:rounded-full ring-1 ring-gray-400 focus:ring-2 focus:ring-blue-800 duration-500
}
.custom-field input.error {
  @apply ring-red-400
}
p.error {
  @apply text-sm ml-3 text-red-500 
}
</style>