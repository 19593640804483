<template>
    <div class="pt-20">
        <iframe class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.0660905841637!2d47.5313855752455!3d-18.884148982281335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x21f08799f1f7408f%3A0x4717ddc2d3dd70dc!2sRaph%20And%20Co!5e0!3m2!1sen!2smg!4v1713789889470!5m2!1sen!2smg"
            width="600" height="450" style="border:0;" allowfullscreen="true" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>

<style>
.map {
    @apply w-full md:rounded-none rounded-3xl
}
</style>