<template>
  <!-- Show VueQueryDevtools only in development mode -->
  <VueQueryDevtools v-if="!isProduction" />
  <MainLayout>
    <template v-slot:main-content>
      <router-view />
    </template>
  </MainLayout>
</template>

<script setup>
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { onMounted } from 'vue'

import MainLayout from './components/layout/MainLayout.vue'
import useNews from './pages/news/composable/useNews'
import { refresh_csrf_token } from './utils/CSRFToken'
const { initializeCookieViewsIds } = useNews()
const isProduction = process.env.NODE_ENV === 'production'

onMounted(async () => {
  AOS.init()
  // initialize csrf cookie on first load of the app
  await refresh_csrf_token()
  // ensure that VIEWED_NEWS_IDS cookie is set
  initializeCookieViewsIds()
})
</script>
