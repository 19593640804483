<template>
  <section id="company-presentation" class="md:py-20 py-10 flex items-center">
    <div class="container grid lg:grid-cols-2 grid-cols-1 gap-5">
        <div data-aos="fade-down" data-aos-duration="1000" class="lg:row-start-1 row-start-2">
            <div class="px-2">
                <h3 class="font-semibold text-lg mb-2 tracking-wider italic text-blue-800">Raph and co c’est … </h3>
                <h1 class="text-3xl font-semibold leading-snug text-gray-600">Un groupe spécialisé dans le développement des talents d'ingénieurs à Madagascar. </h1>
                <p class="my-4">
                    Composé de plusieurs sociétés expertes, notre groupe propose une gamme complète et diversifiée de services pour répondre aux besoins spécifiques des entreprises malgaches.
                </p>
                <p class="mb-4">Chez Raph and Co, notre objectif est de promouvoir l’ingénierie Malgaches et nos talentueux ingénieurs tout en aidant les entreprises à atteindre leurs objectifs de développement grâce à notre savoir-faire et notre engagement.</p>
                <p class="mb-4">
                    Nous offrons des solutions sur mesure grâce à nos différentes entités : 
                </p>
            </div>
            <Flicking :plugins="plugins" class="my-5 sm:pb-10 pb-14" :autoplay="true" :duration="1000" :options="{circular: true}">
                <div class="mission-list">
                    <div class="mission-item">
                        <div class="mission-icon">
                            <div class="icon absolute top-2 left-0 duration-500 ease-in-out">
                                <div class="w-16 h-16 flex items-center justify-center bg-blue-800 rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="text-white w-1/2 h-1/2" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="number absolute top-0 left-0 duration-500 opacity-0">
                                <div class="w-16 h-16 relative">
                                    <span class="absolute -right-2 top-2 h-4 w-4 rounded-full bg-blue-800"></span>
                                    <span class="absolute left-0 -bottom-5 text-lg font-semibold flex items-center justify-center h-14 w-14 rounded-full text-white bg-blue-800">01</span>
                                </div>
                            </div>
                        </div>
                        <div class="px-2 flex-grow">
                            <a href="#"><h2 class="font-semibold relative pb-4 text-2xl duration-500">Raph Management</h2></a>
                            <p class="mt-2">Nous proposons des programmes pour les ingénieurs, comprenant des services de consultance et formation en management personnalisés.</p>
                        </div>
                    </div>
                    
                    <div class="mission-item">
                        <div class="mission-icon">
                            <div class="icon absolute top-2 left-0 duration-500 ease-in-out">
                                <div class="w-16 h-16 flex items-center justify-center bg-blue-800 rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="text-white w-1/2 h-1/2" viewBox="0 0 16 16">
                                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="number absolute top-0 left-0 duration-500 opacity-0">
                                <div class="w-16 h-16 relative">
                                    <span class="absolute -right-2 top-2 h-4 w-4 rounded-full bg-blue-800"></span>
                                    <span class="absolute left-0 -bottom-5 text-lg font-semibold flex items-center justify-center h-14 w-14 rounded-full text-white bg-blue-800">02</span>
                                </div>
                            </div>
                            
                        </div>
                        <div class="px-2 flex-grow">
                            <a href="#"><h2 class="font-semibold relative pb-4 text-2xl duration-500">Raph Engineering</h2></a>
                            <p class="mt-2">Nous fournissons des services de consultance et de formation spécialisés en ingénierie pour répondre aux besoins spécifiques de votre entreprise.</p>
                        </div>
                    </div>
                </div>
                <div class="mission-list">
                    <div class="mission-item">
                        <div class="mission-icon">
                            <div class="icon absolute top-2 left-0 duration-500 ease-in-out">
                                <div class="w-16 h-16 flex items-center justify-center bg-blue-800 rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="text-white w-1/2 h-1/2" viewBox="0 0 16 16">
                                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"/>
                                        <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="number absolute top-0 left-0 duration-500 opacity-0">
                                <div class="w-16 h-16 relative">
                                    <span class="absolute -right-2 top-2 h-4 w-4 rounded-full bg-blue-800"></span>
                                    <span class="absolute left-0 -bottom-5 text-lg font-semibold flex items-center justify-center h-14 w-14 rounded-full text-white bg-blue-800">03</span>
                                </div>
                            </div>
                        </div>
                        <div class="px-2 flex-grow">
                            <a href="#"><h2 class="font-semibold relative pb-4 text-2xl duration-500">Raph Green Estate</h2></a>
                            <p class="mt-2">Nous nous engageons à promouvoir la construction durable en fournissant des services de consultation et de formation spécialisés.</p>
                        </div>
                    </div>
                    
                    <div class="mission-item">
                        <div class="mission-icon">
                            <div class="icon absolute top-2 left-0 duration-500 ease-in-out">
                                <div class="w-16 h-16 flex items-center justify-center bg-blue-800 rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="text-white w-1/2 h-1/2"  viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                    </svg>
                                </div>
                            </div>
                            <div class="number absolute top-0 left-0 duration-500 opacity-0">
                                <div class="w-16 h-16 relative">
                                    <span class="absolute -right-2 top-2 h-4 w-4 rounded-full bg-blue-800"></span>
                                    <span class="absolute left-0 -bottom-5 text-lg font-semibold flex items-center justify-center h-14 w-14 rounded-full text-white bg-blue-800">04</span>
                                </div>
                            </div>
                            
                        </div>
                        <div class="px-2 flex-grow">
                            <a href="#"><h2 class="font-semibold relative pb-4 text-2xl duration-500">Raph Emploi</h2></a>
                            <p class="mt-2">Nous sommes spécialisés dans les solutions de recrutement adaptées à l'ère numérique, offrant expertise et services personnalisés.</p>
                        </div>
                    </div>
                    
                </div>
                <template #viewport>
                    <div class="z-10 absolute bottom-2 sm:bottom-1/2 sm:-translate-y-1/2 right-0 w-full flex justify-between items-center">
                        <span class="flicking-arrow-prev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                            </svg>
                        </span>
                        <span class="flicking-arrow-next">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg>
                        </span>
                    </div>
                    <div class="flicking-pagination mb-2"></div>
                </template>
            </Flicking>
        </div>
        <div class="presention-illustration lg:h-full h-[50vh]">
            <!-- content -->
        </div>
    </div>
  </section>
</template>

<script setup>
import '@egjs/vue3-flicking/dist/flicking.css';

import Flicking from "@egjs/vue3-flicking";
import { Pagination, Arrow  } from "@egjs/flicking-plugins";

const plugins = [
    new Arrow(),
    new Pagination({ type: 'bullet'})
];

</script>

<style scoped>

.flicking-arrow-prev, .flicking-arrow-next {
    @apply inline-flex items-center justify-center z-20
    mr-2  h-10 w-10 rounded-full cursor-pointer duration-300 hover:bg-blue-50 hover:shadow
}
.flicking-arrow-prev svg, .flicking-arrow-next svg {
    @apply w-7 h-7 text-blue-900
}

.mission-list {
    @apply w-full
}
.mission-item {
    @apply cursor-default sm:mb-10 mb-5 sm:px-14 flex sm:flex-row flex-col sm:gap-4 gap-6 items-start justify-between
}
.mission-item .mission-icon {
    @apply  w-1/4 min-w-16 relative min-h-16
}
.mission-item h2::after {
    content: "";
    @apply absolute bottom-0 left-0 h-1 w-16 rounded-lg bg-blue-700 duration-500
}

.btn-outline-primary {
  @apply inline-flex overflow-hidden items-center justify-between gap-2 px-8 py-4 z-10 hover:text-white text-blue-800 border-2 border-blue-800 rounded-lg cursor-pointer shadow relative uppercase tracking-widest text-sm font-semibold
}

.btn-outline-primary::after {
  content: "";
  @apply absolute top-0 left-0 -translate-x-full w-full h-full border-2 border-blue-800 bg-blue-800 -z-10 duration-500 ease-in-out
}

.btn-outline-primary:hover::after {
  @apply translate-x-0
}

.mission-item:hover .icon {
    @apply opacity-0 
}

.mission-item:hover .number {
    @apply opacity-100 
}

.mission-item:hover h2::after {
    @apply w-20
}
.mission-item:hover h2 {
    @apply text-blue-800
}

#company-presentation .presention-illustration {
    background-image: url('~@/assets/presentation company illustration.png');
    @apply bg-contain bg-no-repeat lg:bg-right bg-center hover:-translate-y-2 duration-500  
}
</style>