<template>
  <section id="filiale-raph-green-estate">
    <!-- hero  -->
    <hero-profil
      :bg-url="require('@/assets/filiales/raph-green-estate-hero-2.jpg')"
      :logo="require('@/assets/filiales/logos/logo-raph-green-estate.png')"
      title="Raph Green Estate"
      description="Raph Green Estate excelle dans la construction écologique et durable, privilégiant les matériaux durables, la conception énergétique et la gestion des déchets. Son approche novatrice et son engagement environnemental en font un leader." />

    <div class="py-20 bg-gray-50">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos services</h2>
        <p class="mb-5 md:w-3/5">
          Explorez nos services de qualité, taillés sur mesure pour répondre à vos attentes. De la consultation à la mise en œuvre, nous vous accompagnons avec professionnalisme et engagement à chaque étape.
        </p>

        <div class="service-2-grid">
          <service-card2 v-for="(service, i) in services" :key="i" :image_right="service.image_right"
            :title="service.title" :description="service.description" :image="service.image" />
        </div>
      </div>
    </div>

    <!-- siteweb cta  -->
    <!-- <official-web-site-cta filiale="Raph Green Estate" link="#" /> -->

    <!-- equipe  -->
    <div class="py-20">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos réalisations</h2>
        <p class="mb-5 md:w-3/5">
          Découvrez nos réalisations remarquables, symboles de notre engagement envers l'excellence. Explorez nos succès passés et présents, inspirant confiance pour vos futurs projets.
        </p>

        <div class="realisation-grid my-10">
          <div class="realisation-item" data-aos="zoom-in" data-aos-duration="500" :data-aos-delay="100 * index"
            data-aos-once="true" v-for="(realisation, index) in realisations" :key="index" @click="openLightBox">
            <img :src="realisation">
          </div>

        </div>

      </div>
    </div>
    <FsLightbox :toggler="toggler" :sources="realisations" :key="imageIndex" />

    <!-- equipe  -->
    <div class="py-20">
      <div class="container">
        <team-presentation-card fullname="Zo ANDRIAMPENOMANANA" poste_title="Chief Technical Officer" :presentation="presentation"
          :avatar="team_zo" rounded_avatar="true" />
      </div>
    </div>

    <!-- navigation   -->
    <div class="pb-10">
      <div class="container">
        <filiale-navigation prev_filiale="Raph Emploi" next_filiale="Raph Engineering" prev_pathname="raph-emploi"
          next_pathname="raph-engineering" />
      </div>
    </div>

  </section>
</template>

<script setup>
// images
import RGE_service_1 from "@/assets/services/RGE/service-1.png"
import RGE_service_2 from "@/assets/services/RGE/service-2.png"
import RGE_service_3 from "@/assets/services/RGE/service-3.png"
import RGE_realisation_1 from "@/assets/realisations/RGE/ISO 3.jpg"
import RGE_realisation_2 from "@/assets/realisations/RGE/Concept SUD.png"
import RGE_realisation_3 from "@/assets/realisations/RGE/Concept SUD 4.jpg"
import RGE_realisation_4 from "@/assets/realisations/RGE/Concept SUD 3.jpg"
import RGE_realisation_5 from "@/assets/realisations/RGE/Concept SUD 2.jpg"
import RGE_realisation_6 from "@/assets/realisations/RGE/Concept Gasy modern (2).jpg"

import team_zo from "@/assets/team/zo.png"

// components
import HeroProfil from "../components/HeroProfil.vue"
import FilialeNavigation from "../components/FilialeNavigation.vue"
import ServiceCard2 from "../components/ServiceCard-2.vue"
import TeamPresentationCard from "../components/TeamPresentationCard.vue"
import FsLightbox from "fslightbox-vue/v3";

import { ref } from "vue"
import { useSeoMeta } from '@unhead/vue'

useSeoMeta({
  title: 'Raph Green Estate',
  description: "Raph Green Estate excelle dans la construction écologique et durable à Madagascar, intégrant des matériaux durables et des technologies énergétiques innovantes. Découvrez nos services de conception, promotion immobilière et construction.",
  keywords: "Raph Green Estate, construction écologique, durabilité immobilière, matériaux durables, technologies énergétiques, promotion immobilière durable"
})

const toggler = ref(false)
const imageIndex = ref(7)
const services = [
  {
    title: " Études et Conception",
    description: `<p class="mb-4">Au cœur de notre engagement envers unavenir durable, nos services d'études et de conception s'appuient sur uneapproche novatrice et respectueuse de l'environnement</p>
    <p class="mb-4">Notre équipe d'experts, imprégnée de la vision Raph Green Estate, œuvre à la création de projets architecturaux pensés pour minimiser leur impact écologique. Des analyses approfondies guident nos choix, intégrant les dernières avancées en matière d'ingénierie verte.</p>
    <p class="mb-4">Ensemble, nous concevons des espaces où le design rencontre la durabilité, créant ainsi des environnements harmonieux et éco-responsables
    </p>`,
    image: RGE_service_1,
    image_right: false
  },
  {
    title: "Promotion Immobilière",
    description: `<p class="mb-4"> La promotion immobilière chez Raph Green Estate transcende la simple construction de bâtiments. Nous considérons chaque projet comme une opportunité de façonner des communautés durables et prospères.</p>
    <p class="mb-4"> En collaborant avec des partenaires partageant nos valeurs, nous créons des développements immobiliers qui intègrent des solutions écologiques sans compromettre le confort et le style de vie.</p>
    <p class="mb-4">Notre engagement envers la promotion immobilière durable se traduit par des initiatives innovantes, favorisant un équilibre harmonieux entre urbanisme et préservation de l'environnement</p>`,
    image: RGE_service_2,
    image_right: true
  },
  {
    title: "Construction",
    description: `<p class="mb-4">La construction chez Raph Green Estate repose sur les principes fondamentaux de durabilité et d'efficacité. Nos équipes hautement qualifiées mettent en œuvre des pratiques de construction écologique, utilisant des matériaux durables et des technologies respectueuses de l'environnement.</p>
    <p class="mb-4">Chaque projet est réalisé avec un souci constant de réduire son empreinte carbone et de maximiser l'utilisation d'énergies renouvelables. Nous transformons ainsi des plans novateurs en réalités durables, contribuant à forger un avenir bâti sur la responsabilité environnementale.</p>
    <p class="mb-4">Chez Raph Green Estate, nous mettons notre expertise au service de la création d'environnements durables, de la conception initiale jusqu'à la réalisation concrète. </p>`,
    image: RGE_service_3,
    image_right: false
  }
]

const realisations = [
  RGE_realisation_1,
  RGE_realisation_2,
  RGE_realisation_3,
  RGE_realisation_4,
  RGE_realisation_5,
  RGE_realisation_6
]
const openLightBox = () => {
  toggler.value = !toggler.value
}
const presentation = `
                    <p class="mb-4">La société RGE se concentre sur la construction orienté vers l'écologie et le respect de l'environnement. Ses domaines d'expertise incluent l'utilisation de matériaux durables, la conception énergétique efficace et la gestion des déchets.</p>
                    <p class="mb-4">RGE se démarque sur le marché grâce à son engagement envers la durabilité, ce qui lui confère une position favorable par rapport à ses concurrents traditionnels.</p>
                    <p class="mb-4">L’approche novatrice et l’attention particulière à l'empreinte environnementale font de RGE un leader dans le secteur de la construction durable, offrant des solutions innovantes pour un avenir plus vert et plus durable. </p>
                    `
</script>

<style scoped>
/* Realisation  */
.realisation-grid {
  @apply grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8
}

.realisation-item {
  @apply col-span-1 bg-gray-100 h-[40vh] overflow-hidden rounded-2xl cursor-pointer
}

.realisation-item img {
  @apply block w-full h-full object-cover brightness-90 duration-500 ease-in-out
}

.realisation-item:hover img {
  @apply scale-110 brightness-100
}
</style>