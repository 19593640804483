<template>
  <section class="container-sm" v-if="isPending">
    <single-news-skeleton></single-news-skeleton>
  </section>
  <section class="pt-2" v-if="!isPending && singleNews?.data">
    <div class="container-sm">
      <p
        class="ml-auto py-2 px-4 mb-4 text-right rounded-full tracking-wider text-sm font-semibold ring-1 ring-gray-300 text-gray-500 w-max"
      >
        {{ singleNews.data.views }} vues
      </p>
      <div class="article-banner">
        <img :src="singleNews.data.thumbnailURL" :alt="singleNews.data.title" />
      </div>
    </div>
    <div class="container-sm">
      <article class="news-article" v-html="singleNews.data.content"></article>
    </div>
    <div class="container-sm" v-if="singleNews?.related && singleNews.related.length">
      <h1 class="text-2xl font-bold mb-10">Actualités similaires</h1>
      <div class="news-grid">
        <news-card v-for="item in singleNews.related" :key="item.id" :news="item" />
      </div>
    </div>
    <div class="bg-gray-100 py-10">
      <div class="container flex flex-col justify-center items-center gap-3">
        <router-link
          :to="{ name: 'news' }"
          class="text-blue-900 rounded-full px-8 py-4 border-2 w-max border-blue-900 inline-flex"
          >Voir toutes les actualités</router-link
        >
      </div>
    </div>
  </section>
  <section v-if="errors || isError" class="bg-gray-100 py-20">
    <div class="container flex flex-col justify-center items-center gap-3">
      <h2 class="text-2xl font-semibold py-5 px-8 text-center">L'Article que vous cherchez est introuvable.</h2>
      <router-link
        :to="{ name: 'news' }"
        class="text-blue-900 rounded-full px-8 py-4 border-2 w-max border-blue-900 inline-flex"
        >Revenir aux actualités</router-link
      >
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import NewsCard from '../components/NewsCard.vue'
import useNews from '../composable/useNews'
import SingleNewsSkeleton from '../components/SingleNewsSkeleton.vue'
import { useSeoMeta } from '@unhead/vue'

const route = useRoute()
const slug = computed(() => route.params.slug)

const { errors, useShow } = useNews()
const { data: singleNews, isPending, isError } = useShow(slug)

useSeoMeta({
  title: () => singleNews.value?.data ? `${singleNews.value?.data?.title} | Actualités` : "Actualités",
  description: () => singleNews.value?.data ? singleNews.value?.data?.excerpt : "Découvrez les dernières nouvelles passionnantes de notre entreprise, mettant en lumière nos innovations récentes et nos initiatives dans. Restez informé sur nos projets en cours et notre engagement continu envers l'excellence et l'innovation.",
  keywords: () => singleNews.value?.data ? `Raph and Co actualités, actualités, ${singleNews.value?.data.tagsKeywords}` : "Raph and Co actualités, actualités, nouvelles, communiqué de presse, événement, informations, annonce, dernières nouvelles, innovations, développements, progrès, événements récents, nouveautés, réalisations, découvertes"
})
</script>

<style>
.article-banner {
  @apply rounded-3xl overflow-hidden lg:max-h-[270px] h-[35vh] pointer-events-none;
}

.article-banner img {
  @apply w-full h-full object-cover brightness-75 object-center;
}

.news-article h1 {
  @apply text-4xl font-semibold mb-8 leading-snug;
}

.news-article h2 {
  @apply text-2xl font-semibold py-5 px-8 border-0 border-l-4 border-blue-900 my-10 text-blue-900;
}

.news-article h3 {
  @apply text-2xl font-bold my-10 text-gray-700;
}

.news-article p {
  @apply my-4 text-lg text-justify;
}

.news-article blockquote {
  @apply py-10 text-lg tracking-wide text-blue-900 font-semibold;
}

.news-article ul {
  @apply py-4 list-disc;
}

.news-article ul li {
  @apply ml-5 text-lg;
}

.news-grid {
  @apply grid md:grid-cols-2 grid-cols-1 gap-5;
}
</style>
