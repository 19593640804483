<template>
  <form @submit.prevent="onSubmit" class="contact-form">
    <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
      <div class="col-span-1">
        <text-field placeholder="Votre nom" v-model="first_name" name="first_name" :errors="errors" />
      </div>
      <div class="col-span-1">
        <text-field placeholder="Votre prénom" v-model="last_name" name="last_name" :errors="errors" />
      </div>
    </div>
    <div class="form-control">
      <text-field placeholder="Votre email" v-model="email" name="email" type="email" :errors="errors" />
    </div>
    <div class="form-control">
      <select-field v-model="service" name="service" :options="options" :errors="errors"/>
    </div>
    <div class="form-control">
      <textarea-field v-model="message" name="message" placeholder="Votre message" :errors="errors" />
    </div>
    <div class="form-action flex md:justify-start justify-center">
      <button type="submit" class="btn-contact" :disabled="isSubmitting">
        {{ isSubmitting ? "Envoie du message..." : "Envoyer votre Message" }}
      </button>
    </div>
    <div v-if="success" class="text-green-500 md:text-left text-center mt-5 font-semibold italic">
      <p>Votre message a été envoyé. Raph and Co vous remercie !</p>
    </div>
    <div v-if="backendErrors" class="text-red-400 md:text-left text-center mt-5 font-semibold italic">
      <p>Votre message n'a pas pu être envoyé. Veuillez réessayer ultérieurement.</p>
    </div>
  </form>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import useContact from '../composable/useContact';
import TextField from '@/components/form/TextField.vue';
import SelectField from '@/components/form/SelectField.vue';
import TextareaField from '@/components/form/TextareaField.vue';

const options = [
  { value: 0, label: "Quel service cherchez-vous ?" },
  { value: 1, label: "Recrutement" },
  { value: 2, label: "Service d'ingénierie" },
  { value: 3, label: "Construction" },
  { value: 4, label: "Informations générales" },
]
const success = ref(false)
const { sendMessage, loading: isSubmitting, errors: backendErrors, contactSchema } = useContact()

const { defineField, errors, handleSubmit, resetForm, setErrors } = useForm({
  validationSchema: contactSchema,
  initialValues: {
    service: 0
  },

})
const [first_name] = defineField('first_name')
const [last_name] = defineField('last_name')
const [email] = defineField('email')
const [service] = defineField('service')
const [message] = defineField('message')

const onSuccessValidation = async (values) => {
  // submit code
  success.value = false

  // backend call
  await sendMessage(values)
  if (!backendErrors.value) {
    success.value = true
    resetForm()
  } else {
    setErrors(backendErrors.value)
  }

}
const onValidationFailed = () => success.value = false

const onSubmit = handleSubmit(onSuccessValidation, onValidationFailed)
</script>

<style>
.contact-form {
  @apply my-2 md:my-5 w-full
}

.contact-form input, .contact-form textarea, .contact-form select {
  @apply block w-full px-6 py-3 focus:outline-none rounded-xl md:rounded-full ring-1 ring-gray-400 focus:ring-2 focus:ring-blue-800 duration-500
}

.contact-form input.error, .contact-form textarea.error, .contact-form select.error {
  @apply ring-red-400
}

.contact-form textarea {
  @apply rounded-xl
}

.contact-form select {
  @apply appearance-none cursor-pointer
}

.contact-form select option {
  @apply hover:bg-blue-900
}

.form-control {
  @apply md:my-8 my-5 flex flex-col gap-3
}

.btn-contact {
  @apply h-max items-center justify-center font-semibold tracking-wide px-6 py-3 bg-blue-900 text-white rounded-full hover:bg-blue-800 duration-300
}

span.error {
  @apply text-sm ml-3 text-red-500
}
</style>