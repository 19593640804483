import axiosClient from "@/utils/axios";

const refresh_csrf_token = async () => {
  try {
    return await axiosClient.get("sanctum/csrf-cookie");
  } catch (error) {
    // console.warn("Backend api is not running")
    return
  }
};

export { refresh_csrf_token };
