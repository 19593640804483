<template>
    <div class="flex gap-2 flex-wrap">
        <button @click="setCategorie(categorie)"
            :class="{ 'chip-categorie': true, active: activeCategorieId == categorie.id }"
            v-for="(categorie, i) in allCategories" :key="i">
            {{ categorie.name }}
        </button>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
const emit = defineEmits(['filterByCategorie'])
const props = defineProps({
    categories: {
        type: [Array],
        required: true
    }
})
const allOption = {
    id: 0,
    name: 'All'
}
const allCategories = computed(() => [allOption, ...props.categories] )
const setCategorie = (categorie) => {
    emit('filterByCategorie', categorie)
    activeCategorieId.value = categorie.id
}
const activeCategorieId = ref(allCategories.value[0].id)
</script>

<style scoped>
.chip-categorie {
    @apply px-4 h-10 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-all duration-300
}

.chip-categorie.active {
    @apply bg-primary text-white hover:brightness-110
}
</style>