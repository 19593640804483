// service images
import REN_service_1 from '@/assets/services/REN/service-1.png'
import REN_service_2 from '@/assets/services/REN/service-2.png'

const services = [
  {
    title: 'Consultance',
    description: ` <p class="mb-4">Votre partenaire privilégié en ingénierie et en consultation organisationnelle. Forts d'une expertise avérée et d'une série de réalisations exemplaires, nous nous engageons à fournir des solutions novatrices et durables qui répondent aux besoins les plus pressants de nos partenaires.</p>
      <p class="mb-4">Nos services couvrent une large gamme de domaines, allant de l'énergie renouvelable à la modélisation 3D en passant par l'inspection d'équipements critiques. En tant que consultant organisationnel, nous offrons également un accompagnement technique aux entreprises, les aidant à naviguer dans un paysage complexe en leur fournissant des orientations stratégiques et des solutions sur mesure.</p>`,
    image: REN_service_1,
    image_right: false,
  },
  {
    title: 'Formation au métier technique',
    description: `<p class="mb-4">Au cours des dernières décennies, l’industrie malgache a connu une évolution rapide et constante, avec l’émergence de nouveaux acteurs et l’expansion des entreprises existantes. Cette évolution a apporté son lot de défis et d’opportunités pour les entreprises industrielles, qui doivent constamment s’adapter pour rester compétitives dans un marché en constante évolution.</p>
      <p class="mb-4">Nos formations sont spécifiquement conçues pour répondre aux besoins des entreprises industrielles qui cherchent à développer les compétences de leur personnel et à améliorer leur performance. Nous proposons des formations dans une seule grande catégorie : la formation technique et industrielle.</p>
      <p class="mb-4">Cette formation est axée sur les compétences nécessaires pour assurer la qualité, la sécurité et la productivité dans les opérations industrielles. Nos formations techniques couvrent divers domaines tels que la maintenance, la production, la mécanique, l’électricité, l’énergie et bien plus encore.</p>`,
    image: REN_service_2,
    image_right: true,
  },
]

export default services
