<template>
  <div class="filiale-hero relative"  :style="{ backgroundImage: `url(${props.bgUrl})` }">
    <div class="filiale-hero-content">
      <div class="profil-logo min-w-max">
        <div class="logo-thumbnail">
          <img :src="props.logo" :alt="props.title" />
        </div>
      </div>
      <div class="filiale-presentation">
        <h1 class="text-4xl font-semibold uppercase tracking-wider">{{ props.title }}</h1>
        <p class="mt-5 text-lg">{{ props.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  title: {
    required: true,
    type: String,
  },
  description: {
    type: String,
    required: true,
  },
  bgUrl: {
    required: true,
    type: String,
  },
  logo: {
    required: true,
    type: String,
  }
})
</script>

<style scoped>
/* hero  */
.filiale-hero {
  @apply relative min-h-[82vh] flex items-end py-5 bg-cover bg-center z-10 bg-fixed;
}

.filiale-hero::after {
  @apply content-[''] absolute top-0 left-0 w-full h-full bg-blue-950/50 -z-10;
}

.filiale-hero-content {
  @apply max-w-7xl w-full mx-auto px-5 py-8 text-white rounded-lg flex flex-col md:flex-row gap-8 justify-between items-center;
}

.filiale-presentation {
  @apply flex-grow w-full md:text-start text-center;
}

.profil-logo {
  @apply md:w-64 w-full
}
.logo-thumbnail {
  @apply md:w-60 w-40 md:h-60 h-40 overflow-hidden rounded-xl border-[4px] mx-auto border-gray-200 shadow-xl  bg-white
}

.logo-thumbnail img {
  @apply w-full h-full object-cover
}
</style>
