
const setCookie = (name, value, days = 1) => {
    let expires = ""
    if(typeof value === "object") {
        value = JSON.stringify(value)
    }
    if(days) {
        const date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${encodeURIComponent(value || "")}${expires}; path=/`
}

const getCookie = (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(';')

    let cookieValue = null;
    cookies.forEach(cookie => {
        let c = cookie.trim()
        if(c.indexOf(nameEQ) === 0) {
            cookieValue = decodeURIComponent(c.substring(nameEQ.length, c.length))
        }
    })

    try {
        return JSON.parse(cookieValue)
    } catch (e) {
        return cookieValue
    }
}

export {
    setCookie,
    getCookie
}