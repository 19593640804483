<template>
  <section id="filiale-raph-engineering">
    <!-- hero  -->
    <hero-profil
      :bg-url="require('@/assets/filiales/raph-engineering-hero.jpg')"
      :logo="require('@/assets/filiales/logos/logo-raph-engineering.png')"
      title="Raph Engineering"
      description="Raph Engineering est société qui reprends les activités de raph and co, côté formation
            technique et la consultance pour les industries. " />

    <!-- services  -->
    <div class="py-20 bg-gray-50">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos services</h2>
        <p class="mb-5 md:w-3/5">
          Découvrez nos services sur mesure, pensés pour répondre à vos besoins spécifiques. De la consultation à la mise en œuvre, nous vous offrons un accompagnement professionnel et personnalisé à chaque étape.
        </p>

        <div class="service-2-grid">
          <service-card2 v-for="(service, i) in services" :key="i" :image_right="service.image_right"
            :title="service.title" :description="service.description" :image="service.image" />
        </div>
      </div>
    </div>

    <!-- siteweb cta  -->
    <!-- <official-web-site-cta filiale="Raph Engineering" link="#" /> -->

    <!-- realisation  -->
    <div class="py-20">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos réalisations</h2>
        <p class="mb-5 md:w-3/5">
          Nos réalisations parlent d'elles-mêmes, reflétant notre dévouement à l'excellence. Explorez nos succès passés et actuels pour voir comment nous avons transformé les idées en résultats tangibles.
        </p>
        <realisation-list/>
       
      </div>
    </div>

    <!-- gamme de formation  -->
    <div class="py-10  bg-gradient-to-r from-gray-100 to-gray-200 mb-20">
      <div class="container flex flex-col justify-center items-center">
        <p class="text-center text-lg font-semibold mb-5">Nous avons des formations conçues spécialement pour vous !</p>
        <router-link class="btn-formations" :to="{ name: 'raph-engineering-formations' }">
          <span>Voir le catalogue</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            class="bi bi-arrow-right icon duration-500 ease-out" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </router-link>
      </div>
    </div>

    <!-- equipe  -->
    <div class="py-20">
      <div class="container">
        <team-presentation-card fullname="Mahery Raph" poste_title="General Manager" :presentation="presentation"
          :avatar="team_mahery" />
      </div>
    </div>

    <!-- navigation   -->
    <div class="pb-10">
      <div class="container">
        <filiale-navigation prev_filiale="Raph Green Estate" next_filiale="Raph Engineering"
          prev_pathname="raph-green-estate" next_pathname="raph-engineering" :last="true" />
      </div>
    </div>

  </section>
</template>
<script setup>
// images
import team_mahery from "@/assets/team/mahery.png"

// components
import HeroProfil from "../components/HeroProfil.vue"
import FilialeNavigation from "../components/FilialeNavigation.vue"
import ServiceCard2 from "../components/ServiceCard-2.vue"
import TeamPresentationCard from "../components/TeamPresentationCard.vue"
import services from "./Services"
import { useSeoMeta } from '@unhead/vue'
import RealisationList from "./components/RealisationList.vue"

useSeoMeta({
  title: "Raph Engineering",
  description: "Raph Engineering offre des services de consultation et de formation technique spécialisée pour les industries à Madagascar. Expertise en énergie renouvelable, modélisation 3D, et gestion d'équipements critiques.",
  keywords: "Raph Engineering, consultation industrielle, formation technique, énergie renouvelable, modélisation 3D, gestion d'équipements critiques"
})

const presentation = `
                    <p class=" mb-4">L'ingénierie a toujours été un pilier essentiel du développement sociétal, que ce soit dans les sociétés modernes ou médiévales. Pourtant, à Madagascar, il reste encore beaucoup à faire pour combler l'écart avec les pays développés. </p>
                    <p class="mb-4">C'est là que Raph Engineering intervient. Nous nous engageons tout d'abord à renforcer les compétences de nos ingénieurs locaux en puisant dans notre expertise, issue des avancées majeures à l'échelle internationale. </p>
                    <p class="mb-4">De plus, nos experts métiers contribuent activement au développement des structures d'ingénierie en quête d'innovation et à la modernisation de leur procédés.</p>
                    `

</script>
<style scoped>
.btn-formations {
  @apply px-8 py-4 border-2 border-blue-900 text-blue-900 inline-flex items-center gap-3 w-max rounded-full duration-500 ease-in-out hover:bg-blue-900 hover:text-white
}

.btn-formations:hover .icon {
  @apply translate-x-2
}
</style>