<template>
  <div class="sm:h-[90vh] h-[100vh] flex items-center bg-hero md:bg-center  bg-right bg-blue-200  relative">
    <div class="hero-hoverlay absolute top-0 left-0 w-full h-full bg-primary-200/50"></div>
    <div class="container z-10 -mt-24 hero-content">
      <h1 
      data-aos="fade-right" data-aos-duration="700"
      class="sm:text-6xl text-4xl font-bold leading-snug text-gray-100">Osez l'Excellence, <br> Osez Raph & Co</h1>
      <h2 
      data-aos="fade-right"
      data-aos-delay="200"
      class="text-xl mt-5 max-w-xl text-white">Premier acteur du développement à valoriser les ingénieurs et les techniciens
        Malgache dans le monde professionnel à Madagascar.</h2>
      <div 
      data-aos="fade-right"
      data-aos-delay="400"
      class="mt-10">
        <router-link :to="{name: 'filiales'}" class="btn-primary">
          Nos solutions
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-5 w-5"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style>
.btn-primary {
  @apply inline-flex items-center justify-between gap-2 px-8 py-4 bg-blue-800 text-white rounded-full cursor-pointer shadow relative uppercase tracking-widest text-sm font-semibold
}

.btn-primary::after {
  content: "";
  @apply absolute top-0 left-0 scale-0 w-full h-full bg-white/20 rounded-full duration-200
}

.btn-primary:hover::after {
  @apply scale-100
}

.bg-hero {
  @apply bg-fixed bg-center;
  background-image: url('~@/assets/bg-hero-min2-noir-blanc.jpg');
  background-size: cover;
}

.hero-hoverlay {
  content: "";
}
</style>