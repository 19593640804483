<template>
    <div class="filiale-navigation">
        <div class="filiale-prev">
            <router-link :to="{ name: props.prev_pathname }" :class="['filiale-link', { first: props.first }]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-arrow-left"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                </svg>
                {{ props.prev_filiale }}
            </router-link>
        </div>
        <div class="filiale-next">
            <router-link :to="{ name: props.next_pathname }" :class="['filiale-link', { last: props.last }]">
                {{ props.next_filiale }}
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-arrow-right"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                </svg>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps(['prev_filiale', 'next_filiale', 'next_pathname', 'prev_pathname', 'first', 'last'])
</script>

<style scoped>
/* filiale navigation  */
.filiale-navigation {
    @apply flex md:flex-row gap-5 flex-col justify-between items-center
}

.filiale-link {
    @apply flex items-center gap-5 justify-center min-w-max relative z-10 overflow-hidden text-xl uppercase tracking-wider bg-gray-50 py-5 px-8 rounded-full text-blue-900 ring-1 ring-blue-900
}

.filiale-link::after {
    content: "";
    @apply absolute top-0 w-full h-full bg-blue-100 -z-10 rounded-full
}

.filiale-link svg {
    @apply w-10 h-10 duration-300
}

.filiale-next .filiale-link::after {
    @apply left-full duration-500 ease-in-out
}

.filiale-prev .filiale-link::after {
    @apply right-full duration-500 ease-in-out
}

.filiale-next .filiale-link:hover::after {
    @apply left-0
}

.filiale-prev .filiale-link:hover::after {
    @apply right-0
}

.filiale-prev .filiale-link:hover svg {
    @apply -translate-x-3
}

.filiale-next .filiale-link:hover svg {
    @apply translate-x-3
}

.filiale-link.first, .filiale-link.last {
    @apply pointer-events-none
}

.filiale-link.first svg, .filiale-link.last svg {
    @apply hidden
}
</style>