<template>
    <news-banner />
    <section class="pt-5 pb-20 bg-gray-50">
        <div class="container">
            <!-- categories filters  -->
            <div v-if="isFiltersLoading">
                <news-filter-skeleton></news-filter-skeleton>
            </div>
            <div v-if="isFiltersError">
                <div class="my-4">
                    <alert-message
                        message="Une erreur s'est produite lors du chargement des catégories d'actualités. Veuillez réessayer utlérieurement."
                        color="error"></alert-message>
                </div>
            </div>
            <div v-if="!isFiltersLoading && filtersData" class="mb-5">
                <news-categories-filters :categories="filtersData.categories" @filterByCategorie="handleFilters" />
            </div>

            <!-- news list  -->
            <div v-if="isPending || isFetching">
                <news-list-skeleton></news-list-skeleton>
            </div>
            <div v-if="news && !(isPending || isFetching)">
                <div class="news-grid" v-if="news.data?.length > 0">
                    <news-card v-for="item in news.data" :key="item.id" :news="item" />
                </div>
                <div class="my-4" v-else>
                    <alert-message message="Il n'y pas encore d'actualités de cette catégorie pour le moment."
                        color="info"></alert-message>
                </div>

                <!-- pagination  -->
                <div class="mt-5 flex items-center justify-center"
                    v-if="news?.current_page && news?.last_page && news?.last_page > 1">
                    <pagination-data v-model="page" :length="news.last_page" :totalVisible="3"></pagination-data>
                </div>
            </div>
            <div v-if="isError">
                <div class="my-4">
                    <alert-message
                        message="Une erreur s'est produite lors du chargement des actualités. Veuillez réessayer utlérieurement."
                        color="error"></alert-message>
                </div>
            </div>
        </div>
    </section>
    <news-letter />
</template>

<script setup>
import NewsListSkeleton from './components/NewsListSkeleton.vue'
import NewsFilterSkeleton from './components/NewsFilterSkeleton.vue'
import AlertMessage from '@/components/ui/AlertMessage.vue';
import NewsCard from './components/NewsCard.vue';
import NewsBanner from './components/NewsBanner.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import PaginationData from '@/components/ui/PaginationData.vue';
import NewsCategoriesFilters from "./components/NewsCategoriesFilters.vue"
import { useSeoMeta } from '@unhead/vue'
import useNews from './composable/useNews';
import { ref } from 'vue';
import useFiltersData from './composable/useFiltersData';

useSeoMeta({
    title: "Actualités",
    description: "Découvrez les dernières actualités de Raph and Co. Restez informé sur nos projets, collaborations, et innovations dans le domaine de l'ingénierie à Madagascar. Suivez notre engagement envers le développement des talents et l'excellence professionnelle.",
    keywords: "Raph and Co actualités, projets ingénierie, collaborations Madagascar, innovations entreprises, développement des talents, excellence professionnelle"
})

const page = ref(1)
const filters = ref({
    search: "",
    date: undefined,
    tags: [],
    categories: []
})

const { useIndex } = useNews()
const { data: news, isPending, isFetching, isError } = useIndex(page, filters)

const { data: filtersData, isError: isFiltersError, isPending: isFiltersLoading } = useFiltersData();
const handleFilters = (event) => {
    // all categories 
    if (event.id == 0)
        filters.value = { ...filters.value, categories: [] }
    // filters by categories
    else
        filters.value = { ...filters.value, categories: [event.id] }

    // and always take first page
    page.value = 1
}
</script>

<style scoped>
.news-grid {
    @apply grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 my-8
}
</style>