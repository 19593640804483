<template>
    <div v-if="open" class="md:absolute bottom-5 right-5 rounded-xl text-center bg-white/80 z-20 md:p-5 p-3 md:max-w-xs ring-1 md:ring-0 ring-blue-800 md:m-0 m-5">
        <div class="relative pt-4">
            <!-- close button  -->
            <button class="absolute top-0 right-0 rounded-full border hover:scale-110 duration-300" @click="closeCookie">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="md:w-4 w-5 md:h-4 h-5" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
            </button>
            <!-- content  -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-16 h-1w-16 mx-auto text-blue-900" viewBox="0 0 16 16">
                <path d="M6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m4.5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                <path d="M8 0a7.96 7.96 0 0 0-4.075 1.114q-.245.102-.437.28A8 8 0 1 0 8 0m3.25 14.201a1.5 1.5 0 0 0-2.13.71A7 7 0 0 1 8 15a6.97 6.97 0 0 1-3.845-1.15 1.5 1.5 0 1 0-2.005-2.005A6.97 6.97 0 0 1 1 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 1 0 2.469-1.574A7 7 0 0 1 8 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 1 0 2.005 2.005A6.97 6.97 0 0 1 15 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 1 0-1.025 2.25 7 7 0 0 1-2.51 2.224Z"/>
            </svg>
            <p class="text-sm my-4">Ce site utilise des cookies pour améliorer votre expérience. En continuant votre navigation, vous acceptez notre politique de confidentialité et l'utilisation des cookies</p>
            <button class="btn-primary-sm" @click="acceptCookie">
                Accepter les cookies
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { setCookie, getCookie } from "@/utils/cookieStorage"
const open = ref(true)

const closeCookie = () => {
    setCookie('isAcceptedCookie', false, 10)

    open.value = false
}
const acceptCookie = () => {
    // accept cookie code here
    setCookie('isAcceptedCookie', true, 10)

    // close popup
    open.value = false
}

onMounted(() => {
    const isAcceptedCookie = getCookie('isAcceptedCookie')
    if(isAcceptedCookie) {
        open.value = false
    }
})

</script>

<style>
.btn-primary-sm {
  @apply inline-flex items-center justify-between gap-2 px-6 py-2.5 bg-blue-800 text-white rounded-lg cursor-pointer shadow relative uppercase tracking-widest text-xs font-semibold
}

.btn-primary-sm::after {
  content: "";
  @apply absolute top-0 left-0 scale-0 w-full h-full bg-white/20 rounded-lg duration-200
}

.btn-primary-sm:hover::after {
  @apply scale-100
}
</style>