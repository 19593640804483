<template>
  <section id="filiale-raph-management">
    <!-- hero  -->
    <hero-profil
      :bg-url="require('@/assets/filiales/raph-management-hero-2.jpg')"
      :logo="require('@/assets/filiales/logos/logo-raph-management.png')"
      title="Raph Management"
      description="Raph Management est une entreprise spécialisée dans l’accompagnement
                        d’ingénieurs et
                        des professionnels technique vers l’acquisition de compétences managériales et commerciales." />
    <!-- objectif  -->
    <div class="py-20 bg-white">
      <div class="container">
        <div>
          <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos Objectifs</h2>
          <p class="mb-5 md:w-3/5">
            Nous aspirons à façonner des ingénieurs polyvalents dotés de compétences en entrepreneuriat, gestion et
            leadership, qui seront capables de diriger des équipes, de négocier avec succès, et d'optimiser leur
            évolution professionnel.
          </p>
          <div class="grid md:grid-cols-3 grid-cols-1 gap-10 mt-10 w-full">
            <div class="col-span-1 flex p-5 ring-2 ring-gray-100 justify-between rounded-3xl">
              <div class="min-w-16 pt-2 h-full text-blue-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-10 w-10" viewBox="0 0 16 16">
                  <path
                    d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a2 2 0 0 0-.453-.618A5.98 5.98 0 0 1 2 6m6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1" />
                </svg>
              </div>
              <div>
                <h2 class="font-semibold text-2xl mb-3 text-gray-600">Expertise holistique</h2>
                <p>
                  Fournir des solutions holistiques pour le développement professionnel des ingénieurs techniciens, en
                  les préparant à relever les défis complexes de l'environnement professionnel actuel.
                </p>
              </div>
            </div>
            <div class="col-span-1 flex p-5 ring-2 ring-gray-100 justify-between rounded-3xl">
              <div class="min-w-16 pt-2 h-full text-blue-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-10 w-10" viewBox="0 0 16 16">
                  <path
                    d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
                </svg>
              </div>
              <div>
                <h2 class="font-semibold text-2xl mb-3 text-gray-600">Compétences clés</h2>
                <p>
                  Nous visons à élargir leur expertise technique en les dotant des compétences nécessaires en matière de
                  leadership, de gestion, de négociation et de communication.
                </p>
              </div>
            </div>
            <div class="col-span-1 flex p-5 ring-2 ring-gray-100 justify-between rounded-3xl">
              <div class="min-w-16 pt-2 h-full text-blue-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-10 w-10" viewBox="0 0 16 16">
                  <path
                    d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                  <path
                    d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                </svg>
              </div>
              <div>
                <h2 class="font-semibold text-2xl mb-3 text-gray-600">Écosystème propice</h2>
                <p>
                  Grâce à nos services de consultance, de formation et de recrutement spécialisé, nous souhaitons créer
                  un écosystème propice à la transformation personnelle et professionnelle de nos clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- siteweb cta  -->
    <!-- <official-web-site-cta filiale="Raph Management" link="#" /> -->

    <!-- services  -->
    <div class="bg-gray-50 md:py-20 py-10">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos services</h2>
        <p class="mb-5 md:w-3/5">
          Découvrez nos services spécialisés, conçus pour répondre à vos besoins avec expertise et dévouement. De la
          consultation personnalisée à la mise en œuvre, nous sommes là pour vous soutenir à chaque étape.
        </p>
        <div class="service-grid">
          <service-card1
            v-for="(service, i) in services"
            :key="i"
            :title="service.title"
            :description="service.description"
            :details="service.details"
            :image="service.image" />
        </div>
      </div>
    </div>

    <!-- realisation  -->
    <div class="py-20">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos réalisations</h2>
        <p class="mb-5 md:w-3/5">
          Explorez nos succès passés et présents, fruits de notre engagement envers l'excellence. Découvrez comment nous
          avons innové et réalisé des projets exceptionnels pour nos clients.
        </p>

        <div class="realisation-grid my-10">
          <div
            class="realisation-item"
            data-aos="zoom-in"
            data-aos-duration="500"
            :data-aos-delay="100 * index"
            data-aos-once="true"
            v-for="(realisation, index) in realisations"
            :key="index"
            @click="openLightBox">
            <img :src="realisation" />
          </div>
        </div>
      </div>
    </div>
    <FsLightbox :toggler="toggler" :sources="realisations" :key="imageIndex" />

    <!-- gamme de formation  -->
    <div class="py-10 bg-gradient-to-r from-gray-100 to-gray-200 mb-20">
      <div class="container flex flex-col justify-center items-center">
        <p class="text-center text-lg font-semibold mb-5">Nous avons des formations conçues spécialement pour vous !</p>
        <router-link class="btn-formations" :to="{ name: 'raph-management-formations' }">
          <span>Voir le catalogue</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-right icon duration-500 ease-out"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </router-link>
      </div>
    </div>
    <!-- equipe  -->
    <div class="py-20">
      <div class="container">
        <team-presentation-card
          fullname="Miary Rajaonah"
          poste_title="Strategic Manager"
          :presentation="presentation"
          :avatar="team_miary" />
      </div>
    </div>

    <!-- navigation   -->
    <div class="pb-10">
      <div class="container">
        <filiale-navigation
          prev_filiale="Raph Management"
          next_filiale="Raph Emploi"
          prev_pathname="raph-management"
          next_pathname="raph-emploi"
          :first="true" />
      </div>
    </div>
  </section>
</template>
<script setup>
// images
// import waveIMG from "@/assets/vague.png"
import RM_service_1 from '@/assets/services/RM/service1.jpg'
import RM_service_2 from '@/assets/services/RM/service2.jpg'

import RM_realisation_1 from '@/assets/realisations/RM/realisation_1.jpg'
import RM_realisation_2 from '@/assets/realisations/RM/realisation_2.jpg'
import RM_realisation_3 from '@/assets/realisations/RM/realisation_3.jpg'
import RM_realisation_4 from '@/assets/realisations/RM/realisation_4.jpg'
import RM_realisation_5 from '@/assets/realisations/RM/realisation_5.jpg'
import RM_realisation_6 from '@/assets/realisations/RM/realisation_6.jpg'

import team_miary from '@/assets/team/miary.png'

// components
import HeroProfil from '../components/HeroProfil.vue'
import FilialeNavigation from '../components/FilialeNavigation.vue'
import ServiceCard1 from '../components/ServiceCard-1.vue'
import TeamPresentationCard from '../components/TeamPresentationCard.vue'
import FsLightbox from 'fslightbox-vue/v3'

import { ref } from 'vue'
import { useSeoMeta } from '@unhead/vue'

useSeoMeta({
  title: 'Raph Management',
  description:
    'Raph Management forme des ingénieurs en leadership et gestion, offrant des solutions holistiques pour leur développement professionnel. Consultance personnalisée et formations spécialisées.',
  keywords:
    'Raph Management, ingénieurs leadership, compétences managériales, formations personnalisées, consultance spécialisée',
})
const toggler = ref(false)
const imageIndex = ref(1)

const services = [
  {
    title: 'Formation & Accompagnement',
    description:
      'Ce service offre des programmes personnalisés pour renforcer les compétences professionnelles et favoriser le développement individuel.',
    image: RM_service_1,
    details: [
      'Management et leadership',
      'Technique de vente et négociation',
      'Marketing',
      'Entrepreneuriat',
      'Techniques de candidature efficaces',
    ],
  },
  {
    title: 'Consultance',
    description:
      'Expertise personnalisée pour répondre aux besoins spécifiques des clients, assurant des solutions adaptées et efficaces en consultance.',
    image: RM_service_2,
    details: [
      "Gestion de projet et Création d'entreprise",
      'Gestion des ressources humaines',
      'Gestion de production et Marketing',
      'Stratégie commerciale',
      'Management positif et gestion de stress',
    ],
  },
]

const realisations = [
  RM_realisation_1,
  RM_realisation_2,
  RM_realisation_3,
  RM_realisation_4,
  RM_realisation_5,
  RM_realisation_6,
]

const openLightBox = () => {
  toggler.value = !toggler.value
}

const presentation = `
                    <p class=" mb-4">Raph Management se positionne comme un guide pour les ingénieurs et les professionnels techniques, les accompagnant dans l'acquisition de compétences managériales et commerciales.</p>
                    <p class="mb-4">Notre entreprise s'engage à offrir des programmes de formation adaptés aux besoins spécifiques de chaque individu, favorisant ainsi leur développement professionnel.</p>
                    <p class="mb-4">Nous visons à être le partenaire de confiance pour ceux qui aspirent à élargir leurs horizons et à réussir dans des rôles de leadership et de gestion.</p>
                    `
</script>

<style scoped>
/* service  */
.service-grid {
  @apply grid md:grid-cols-2 grid-cols-1 gap-10 mt-10;
}

/* Realisation  */
.realisation-grid {
  @apply grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8;
}

.realisation-item {
  @apply col-span-1 bg-gray-100 lg:min-h-[394px] min-h-[40vh] overflow-hidden rounded-2xl cursor-pointer;
}

.realisation-item img {
  @apply block w-full h-full object-cover brightness-90 duration-500 ease-in-out;
}

.realisation-item:hover img {
  @apply scale-110 brightness-100;
}

.btn-formations {
  @apply px-8 py-4 border-2 border-blue-900 text-blue-900 inline-flex items-center gap-3 w-max rounded-full duration-500 ease-in-out hover:bg-blue-900 hover:text-white;
}

.btn-formations:hover .icon {
  @apply translate-x-2;
}
</style>
