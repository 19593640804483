<template>
  <section class="md:py-20 py-10">
    <div class="container">
      <h2 class="text-4xl text-blue-900 font-semibold text-center mb-5">Nos Partenaires</h2>
        <p data-aos-delay="200" class="text-center md:w-1/2 mx-auto mb-5">Dans notre parcours vers l'excellence, nous sommes honorés de pouvoir compter sur le soutien et la collaboration de nos partenaires de confiance.</p>
      <Flicking :options="{ circular: true }" :plugins="plugins" class="logos-carousel">
        <div v-for="partenaire in partenaires" data-aos-once="true" data-aos="zoom-in" data-aos-delay="100" :key="partenaire.id" class="partenaire-logo">
          <img :src="partenaire.logo" :alt="partenaire.name">
        </div>
       
        <template #viewport>
          <div data-aos-once="true" data-aos="fade-right" class="arrow-prev-container">
            <span class="flicking-arrow-prev">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
              </svg>
            </span>
          </div>
          <div data-aos-once="true" data-aos="fade-left" class="arrow-next-container">
            <span class="flicking-arrow-next">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
              </svg>
            </span>
          </div>
        </template>
      </Flicking>

    </div>
  </section>
</template>

<script setup>

import LogoPartenaire_1 from "@/assets/logos/logo_partenaire (1).jpg"
import LogoPartenaire_2 from "@/assets/logos/logo_partenaire (2).png"
import LogoPartenaire_3 from "@/assets/logos/logo_partenaire (3).png"
import LogoPartenaire_4 from "@/assets/logos/logo_partenaire (4).png"
import LogoPartenaire_5 from "@/assets/logos/logo_partenaire (5).png"
import LogoPartenaire_6 from "@/assets/logos/logo_partenaire (6).png"
import LogoPartenaire_7 from "@/assets/logos/logo_partenaire (7).png"
import LogoPartenaire_8 from "@/assets/logos/logo_partenaire (8).png"
import LogoPartenaire_9 from "@/assets/logos/logo_partenaire (9).png"
import LogoPartenaire_10 from "@/assets/logos/logo_partenaire (10).png"
import LogoPartenaire_11 from "@/assets/logos/logo_partenaire (11).png"
import LogoPartenaire_12 from "@/assets/logos/logo_partenaire (12).png"
import LogoPartenaire_13 from "@/assets/logos/logo_partenaire (13).png"
import LogoPartenaire_14 from "@/assets/logos/logo_partenaire (14).png"
import LogoPartenaire_15 from "@/assets/logos/logo_partenaire (15).png"
import LogoPartenaire_16 from "@/assets/logos/logo_partenaire (16).png"
import LogoPartenaire_17 from "@/assets/logos/logo_partenaire (17).png"
import LogoPartenaire_18 from "@/assets/logos/logo_partenaire (18).png"
import LogoPartenaire_19 from "@/assets/logos/logo_partenaire (19).png"
import LogoPartenaire_20 from "@/assets/logos/logo_partenaire (20).png"
import LogoPartenaire_21 from "@/assets/logos/logo_partenaire (21).png"
import LogoPartenaire_22 from "@/assets/logos/logo_partenaire (22).png"
import LogoPartenaire_23 from "@/assets/logos/logo_partenaire (23).png"
import LogoPartenaire_24 from "@/assets/logos/logo_partenaire (24).png"
import LogoPartenaire_25 from "@/assets/logos/logo_partenaire (25).png"
import LogoPartenaire_26 from "@/assets/logos/logo_partenaire (26).png"
import LogoPartenaire_27 from "@/assets/logos/logo_partenaire (27).png"

import Flicking from "@egjs/vue3-flicking"
import { Arrow, AutoPlay } from "@egjs/flicking-plugins";


const plugins = [
  new Arrow({ moveCount: 3 }),
  new AutoPlay({duration: 3000})
]

const partenaires = [
  { id: 1, name: "Sogea Satom", logo: LogoPartenaire_1 },
  { id: 2, name: "Majingo", logo: LogoPartenaire_2 },
  { id: 3, name: "Refitany Geometre expert", logo: LogoPartenaire_3 },
  { id: 4, name: "Trianlge Horizon", logo: LogoPartenaire_4 },
  { id: 5, name: "Socfin", logo: LogoPartenaire_5 },
  { id: 6, name: "Asa", logo: LogoPartenaire_6 },
  { id: 7, name: "Saf", logo: LogoPartenaire_7 },
  { id: 8, name: "Taloumis", logo: LogoPartenaire_8 },
  { id: 9, name: "Newpack", logo: LogoPartenaire_9 },
  { id: 10, name: "Alliance Externe", logo: LogoPartenaire_10 },
  { id: 11, name: "Feline", logo: LogoPartenaire_11 },
  { id: 12, name: "Biogasikara Energy", logo: LogoPartenaire_12 },
  { id: 13, name: "Ravinala Airport", logo: LogoPartenaire_13 },
  { id: 14, name: "Sahanala", logo: LogoPartenaire_14 },
  { id: 15, name: "Central test", logo: LogoPartenaire_15 },
  { id: 16, name: "Madacan", logo: LogoPartenaire_16 },
  { id: 17, name: "Lecofruit", logo: LogoPartenaire_17 },
  { id: 18, name: "Corrupal", logo: LogoPartenaire_18 },
  { id: 19, name: "Agence routiere", logo: LogoPartenaire_19 },
  { id: 20, name: "FIDIC", logo: LogoPartenaire_20 },
  { id: 21, name: "Afrotel Group", logo: LogoPartenaire_21 },
  { id: 22, name: "ADES", logo: LogoPartenaire_22 },
  { id: 23, name: "RD Consulting", logo: LogoPartenaire_23 },
  { id: 24, name: "Axian", logo: LogoPartenaire_24 },
  { id: 25, name: "Epsilon", logo: LogoPartenaire_25 },
  { id: 26, name: "Econs", logo: LogoPartenaire_26 },
  { id: 27, name: "Oti", logo: LogoPartenaire_27 }
]

</script>

<style scoped>
.logos-carousel {
  @apply my-10 
}

.arrow-prev-container, .arrow-next-container {
  @apply absolute h-full md:w-20 w-16 bg-white z-10 top-0 flex items-center justify-center
}
.arrow-prev-container {
  @apply -left-1
}
.arrow-next-container {
  @apply -right-2
}
.partenaire-logo {
  @apply w-32 self-center mx-5
}

.partenaire-logo img {
  @apply object-contain w-full grayscale h-auto duration-500
}

.partenaire-logo:hover img {
  @apply grayscale-0 scale-105
}

.flicking-arrow-prev, .flicking-arrow-next {
  @apply flex items-center justify-center z-20 mr-2 h-14 w-14 rounded-full cursor-pointer duration-300 hover:bg-blue-50 hover:shadow
}

.flicking-arrow-prev svg, .flicking-arrow-next svg {
  @apply w-8 h-8  text-blue-900
}
</style>