<template>
 <section class="contact-banner">
    <h1>Contactez nous</h1>
    <p class="md:w-2/3 px-4 text-white">Pour toute demande d'assistance ou d'information supplémentaire, veuillez nous contacter. Notre équipe est disponible pour répondre à vos questions et vous fournir le soutien nécessaire.</p>
 </section>
</template>


<style scoped>
.contact-banner {
    @apply flex-col gap-5 bg-[url('~@/assets/contact-bg.jpg')] relative z-10
    bg-cover bg-bottom flex items-center justify-start pt-[10vh] text-center h-[60vh] lg:max-h-[465px] lg:pt-[80px]
    after:content-[''] after:w-full after:h-full after:absolute after:-z-10 after:bg-blue-950/50 after:top-0 after:left-0
}
.contact-banner h1 {
    @apply text-5xl font-semibold text-white
}
</style>