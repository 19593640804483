<template>
    <section class="min-h-[70vh] pb-10 flex items-center justify-center">
        <div class="container text-center flex items-center justify-center flex-col">
            <img src="OnBuilding_page.svg" alt="404 Image" class="max-w-[520px] min-w-[260px] w-1/3">
            <h2 class="md:text-4xl text-3xl font-bold text-gray-500 mb-5">Page en cours de construction...</h2>
            <p class="font-semibold md:text-lg mb-5">Revisitez cette page ultérieurement. Un contenu extraordinaire vous attendra ici.</p>
            <router-link class="btn-primary" :to="{ name: 'home' }">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                    class="bi bi-arrow-left icon duration-500 ease-out" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                </svg>
                <span>Revenir à l'Acceuil</span>
            </router-link >
        </div>
    </section>
</template>

<script setup>
import { useSeoMeta } from '@unhead/vue'
useSeoMeta({
  title: "Page en cours de construction",
  description: "Nous sommes en train de construire une nouvelle page passionnante chez Raph and Co. Revenez bientôt pour découvrir nos dernières mises à jour et nos services d'ingénierie à Madagascar.",
  keywords: "en construction, bientôt disponible, Raph and Co, ingénierie Madagascar, nouvelles mises à jour"
})
</script>