<template>
    <div class="realisations-card">
        <div class="realisations-image" @click="openLightbox">
            <img v-for="(image, index) in props.realisation.images.slice(0, 3)" :src="image"
                :alt="props.realisation.title" :class="`z-[${index * 10}]`" :key="index">
        </div>
        <div class="realisations-content">
            <div class="realisations-detail">
                <p class="font-semibold">{{ realisation.annee }}</p>
                <h3>{{ realisation.title }}</h3>
                <p class="text-xl">Client : {{ realisation.client }}</p>
            </div>
            <div class="client-logo" v-if="realisation.client_logo">
                <img :src="realisation.client_logo" :alt="realisation.client">
            </div>
        </div>
        <FsLightbox :toggler="toggler" :sources="props.realisation.images" :loadOnlyCurrentSource="true" type="image" />
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
const props = defineProps(['realisation'])
import FsLightbox from "fslightbox-vue/v3";

const toggler = ref(false)
const openLightbox = () => {
    toggler.value = !toggler.value
}
</script>

<style scoped>
.realisations-card {
    @apply grid md:grid-cols-3 grid-cols-1 gap-10 py-10 border-b-2 border-0 border-gray-200
}

.realisations-card:nth-child(even) {
    @apply text-left md:text-right
}
.realisations-card:nth-child(even) .realisations-detail {
    @apply md:order-2 order-1
}
.realisations-card:nth-child(even) .client-logo {
    @apply md:order-1 order-2
}

.realisations-card:nth-child(odd) {
    @apply text-left
}

.realisations-card:nth-child(even) .realisations-image {
    @apply order-2
}

.realisations-card:nth-child(even) .realisations-content {
    @apply order-1
}

.realisations-image {
    @apply md:col-span-1 lg:max-h-[272px] h-[30vh] relative cursor-pointer order-1
}

.realisations-image img {
    @apply w-full h-full object-cover rounded-3xl absolute top-0 right-0 border-2 border-gray-100 duration-500 brightness-95
}

.realisations-image img:nth-child(1), .realisations-image img:nth-child(2) {
    @apply duration-300 border-2 border-gray-100
}

.realisations-image:hover img:nth-child(1) {
    @apply -rotate-12
    /* @apply translate-x-4 -translate-y-4 */
}

.realisations-image:hover img:nth-child(2) {
    @apply -rotate-6
    /* @apply translate-x-2 -translate-y-2 */
}

.realisations-content {
    @apply col-span-1 md:col-span-2 text-lg p-5 order-2 flex flex-col md:flex-row items-center justify-between gap-5
}
.realisations-content .realisations-detail {
    @apply w-full
}
.client-logo img {
    @apply min-w-20 
}
.realisations-content h3 {
    @apply font-semibold my-3 text-xl
}
</style>