import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/fr";

// date format fr
dayjs.extend(localizedFormat)
dayjs.locale('fr')

const dateToFrench = (date) => {
  return  dayjs(date).format('D MMMM YYYY')
}
export default dateToFrench
