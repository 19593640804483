<template>
 <section class="news-banner">
    <h1>Nos dernières nouvelles</h1>
    <p class="md:w-2/3 px-4 text-white">Explorez nos dernières actualités pour des mises à jour sur nos produits, des astuces utiles, ainsi que des nouvelles internationales. Restez informé sur nos avancées conseils pratiques.</p>
 </section>
</template>


<style scoped>
.news-banner {
    @apply flex-col gap-5 bg-[url('~@/assets/news-bg.jpg')] relative z-10
    bg-cover bg-bottom flex items-center justify-center text-center h-[60vh] lg:max-h-[465px]
    after:content-[''] after:w-full after:h-full after:absolute after:-z-10 after:bg-blue-950/50 after:top-0 after:left-0
}
.news-banner h1 {
    @apply text-5xl font-semibold text-white
}
</style>