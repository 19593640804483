// realisations images

import REN_realisation_1_1 from '@/assets/realisations/REN/realisation_1_1.jpg'
import REN_realisation_1_2 from '@/assets/realisations/REN/realisation_1_2.jpg'
import REN_realisation_1_3 from '@/assets/realisations/REN/realisation_1_3.jpg'
import REN_realisation_1_4 from '@/assets/realisations/REN/realisation_1_4.png'

import REN_realisation_2_1 from '@/assets/realisations/REN/realisation_2_1.png'
import REN_realisation_2_2 from '@/assets/realisations/REN/realisation_2_2.png'

import REN_realisation_3_1 from '@/assets/realisations/REN/realisation_3_1.png'
import REN_realisation_3_2 from '@/assets/realisations/REN/realisation_3_2.png'
import REN_realisation_3_3 from '@/assets/realisations/REN/realisation_3_3.png'
import REN_realisation_3_4 from '@/assets/realisations/REN/realisation_3_4.png'

import REN_realisation_4_1 from '@/assets/realisations/REN/realisation_4_1.jpg'
import REN_realisation_4_2 from '@/assets/realisations/REN/realisation_4_2.jpg'
import REN_realisation_4_3 from '@/assets/realisations/REN/realisation_4_3.jpg'
import REN_realisation_4_4 from '@/assets/realisations/REN/realisation_4_4.jpg'
import REN_realisation_4_5 from '@/assets/realisations/REN/realisation_4_5.jpg'
import REN_realisation_4_6 from '@/assets/realisations/REN/realisation_4_6.jpg'

import REN_realisation_5_1 from '@/assets/realisations/REN/realisation_5_1.jpg'
import REN_realisation_5_2 from '@/assets/realisations/REN/realisation_5_2.jpg'
import REN_realisation_5_3 from '@/assets/realisations/REN/realisation_5_3.jpg'
import REN_realisation_5_4 from '@/assets/realisations/REN/realisation_5_4.jpg'

import REN_realisation_6_1 from '@/assets/realisations/REN/realisation_6_1.jpg'
import REN_realisation_6_2 from '@/assets/realisations/REN/realisation_6_2.jpg'
import REN_realisation_6_3 from '@/assets/realisations/REN/realisation_6_3.jpg'

import REN_realisation_7_1 from '@/assets/realisations/REN/realisation_7_1.jpg'
import REN_realisation_7_2 from '@/assets/realisations/REN/realisation_7_2.jpg'
import REN_realisation_7_3 from '@/assets/realisations/REN/realisation_7_3.jpg'
import REN_realisation_7_4 from '@/assets/realisations/REN/realisation_7_4.jpg'
import REN_realisation_7_5 from '@/assets/realisations/REN/realisation_7_5.jpg'
import REN_realisation_7_6 from '@/assets/realisations/REN/realisation_7_6.jpg'

import REN_realisation_8_1 from '@/assets/realisations/REN/realisation_8_1.jpg'
import REN_realisation_8_2 from '@/assets/realisations/REN/realisation_8_2.jpg'
import REN_realisation_8_3 from '@/assets/realisations/REN/realisation_8_3.jpg'


// logos
import Logo_1 from '@/assets/realisations/REN/logos/triangle_horizon.png'
import Logo_2 from '@/assets/realisations/REN/logos/corrupal.png'
import Logo_3 from '@/assets/realisations/REN/logos/rd_consulting.png'
import Logo_4 from '@/assets/realisations/REN/logos/majinco.png'
import Logo_5 from '@/assets/realisations/REN/logos/lecofruit.png'
import Logo_6 from '@/assets/realisations/REN/logos/sogea_satom.png'
import Logo_7 from '@/assets/realisations/REN/logos/ravinala_airport.png'
// import Logo_8 from '@/assets/realisations/REN/logos/sogea_satom.png'


const realisations = [
  {
    id: 1,
    title: 'Projet Ambatolampy - 20Mw de Champ Solaire',
    images: [
      REN_realisation_1_1,
      REN_realisation_1_2,
      REN_realisation_1_3,
      REN_realisation_1_4,
    ],
    annee: 2021,
    client: "TRIANGLE HORIZON",
    client_logo: Logo_1
  },
  {
    id: 2,
    title: 'Projet de modélisation 3D',
    images: [REN_realisation_2_1, REN_realisation_2_2],
    annee: 2022,
    client: "CORRUPAL",
    client_logo: Logo_2
  },
  {
    id: 3,
    title:
      'Réalisation de Plan d’inspection d’équipement statique et sous pression pour une société de Mine d’Or',
    images: [
      REN_realisation_3_1,
      REN_realisation_3_2,
      REN_realisation_3_3,
      REN_realisation_3_4,
    ],
    annee: 2022,
    client: "RD CONSULTING",
    client_logo: Logo_3
  },
  {
    id: 8,
    title: 'Formation en PAO',
    images: [REN_realisation_8_1, REN_realisation_8_2, REN_realisation_8_3],
    annee: 2022,
    client: "MESupREs - UNICEF",
    client_logo: ''
  },
  {
    id: 4,
    title: 'Installation Solaire sur 17 Sites dans la région Diana en 2023',
    images: [
      REN_realisation_4_1,
      REN_realisation_4_2,
      REN_realisation_4_3,
      REN_realisation_4_4,
      REN_realisation_4_5,
      REN_realisation_4_6,
    ],
    annee: 2023,
    client: "MAJINCO",
    client_logo: Logo_4
  },
  {
    id: 5,
    title: 'Formation en chaudronnerie Fine',
    images: [
      REN_realisation_5_1,
      REN_realisation_5_2,
      REN_realisation_5_3,
      REN_realisation_5_4,
    ],
    annee: 2023,
    client: "LECO FRUIT",
    client_logo: Logo_5
  },
  {
    id: 6,
    title: 'Formation en Schéma électrique',
    images: [REN_realisation_6_1, REN_realisation_6_2, REN_realisation_6_3],
    annee: 2023,
    client: "SOGEA SATOM",
    client_logo: Logo_6
  },
  {
    id: 7,
    title: 'Formation en système photovoltaïque',
    images: [
      REN_realisation_7_1,
      REN_realisation_7_2,
      REN_realisation_7_3,
      REN_realisation_7_4,
      REN_realisation_7_5,
      REN_realisation_7_6,
    ],
    annee: 2023,
    client: "RAVINALA AIRPORT",
    client_logo: Logo_7
  },
]

export default realisations.reverse()
