<template>
  <div class="container">
    <flicking ref="datesFlicking" class="md:px-5 mb-8" :options="datesOptions" :plugins="datesPlugins">
      <div :key="1" class="date-panel">
        <h4>2020</h4>
        <icon-record-circle class="date-icon" />
      </div>
      <div :key="2" class="date-panel">
        <h4>2021</h4>
        <icon-record-circle class="date-icon" />
      </div>
      <div :key="3" class="date-panel">
        <h4>2022</h4>
        <icon-record-circle class="date-icon" />
      </div>
      <div :key="4" class="date-panel">
        <h4>2023</h4>
        <icon-record-circle class="date-icon" />
      </div>
      <div :key="5" class="date-panel">
        <h4>2024</h4>
        <icon-record-circle class="date-icon" />
      </div>
    </flicking>

    <flicking ref="historiesFlicking" class="md:p-5" :options="historiesOptions" :plugins="historiesPlugins">
      <div :key="1" class="history-panel">
        <div class="history-img">
          <img :src="history2020" class="logo" />
        </div>
        <div class="history-content">
          <h3 class="history-title">Création de la société Raph and Co</h3>
          <p class="history-detail">
            Création de la société Raph and Co, premier cabinet de recrutement spécialisé dans le domaine de
            l’ingénieur.
          </p>
        </div>
      </div>
      <div :key="2" class="history-panel">
        <div class="history-img">
          <img :src="history2021" />
        </div>
        <div class="history-content">
          <h3 class="history-title">Premier grand marché pour Raph and Co</h3>
          <p class="history-detail">
            Premier grand marché pour Raph and Co, en contribuant à la réalisation du central solaire d’Ambatolampy
            phase 2 , de 20Mw.
          </p>
        </div>
      </div>
      <div :key="3" class="history-panel">
        <div class="history-img">
          <img :src="history2022" class="logo" />
        </div>
        <div class="history-content">
          <h3 class="history-title">Création de Raph Management</h3>
          <p class="history-detail">
            Création de Raph Management, société filiale de Raph and Co, se spécialisant dans l’accompagnement des
            ingénieurs dans le domaine du management et leadership
          </p>
        </div>
      </div>
      <div :key="4" class="history-panel">
        <div class="history-img">
          <img :src="history2023" />
        </div>
        <div class="history-content">
          <h3 class="history-title">Conquête du marché international</h3>
          <p class="history-detail">
            Conquête du marché international, en proposant des formations d’envergure international en partenariat avec
            la Fédération international des Ingénieurs Conseils FIDIC. Recrutement International pour le groupe SOCFIN,
            leader de la plantation d’huile de palme et de production de caoutchouc en Afrique et en Indonésie.
          </p>
        </div>
      </div>
      <div :key="5" class="history-panel">
        <div class="history-img">
          <img :src="history2024" class="logo" />
          <img :src="history2024_2" class="logo" />
        </div>
        <div class="history-content">
          <h3 class="history-title">Création de Raph Emploi, Raph Green Estate et Raph Engineering</h3>
          <p class="history-detail">
            La fondation de Raph Emploi, Raph Green Estate et Raph Engineering marque le début d'une aventure
            entrepreneuriale passionnante, où chaque entreprise incarne notre engagement envers l'innovation, la
            durabilité et l'excellence.
          </p>
        </div>
      </div>
    </flicking>
  </div>
</template>

<script setup>
import { Sync } from '@egjs/flicking-plugins'
import Flicking from '@egjs/vue3-flicking'
import '@egjs/vue3-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/arrow.css'
import { onMounted, ref } from 'vue'
import IconRecordCircle from '@/components/icons/IconRecordCircle.vue'

import history2020 from '@/assets/history2020.png'
import history2024 from '@/assets/history2024.png'
import history2024_2 from '@/assets/history2024_2.png'
import history2023 from '@/assets/history2023.png'
import history2022 from '@/assets/history2022.png'
import history2021 from '@/assets/history2021.jpg'

const datesFlicking = ref(null)
const historiesFlicking = ref(null)

const datesPlugins = ref([])
const historiesPlugins = ref([])

const datesOptions = {
  panelsPerView: 5,
  align: 'prev',
  bound: false,
}
const historiesOptions = {
  panelsPerView: 1,
  align: 'prev',
}

onMounted(() => {
  datesPlugins.value = [
    new Sync({
      type: 'index',
      synchronizedFlickingOptions: [
        {
          flicking: datesFlicking.value,
          isClickable: true,
          activeClass: 'active',
        },
        {
          flicking: historiesFlicking.value,
          isSlidable: true,
        },
      ],
    }),
  ]
})
</script>

<style scoped>
.date-panel {
  @apply p-8 mb-5 w-full min-w-[120px] text-center cursor-pointer relative z-10;
}
.date-panel h4 {
  @apply font-semibold h-10  text-gray-500 text-2xl;
}
.date-panel .date-icon {
  @apply w-6 h-6 text-gray-400 absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 z-20;
}
.date-panel::before {
  @apply absolute content-[""] w-full h-[2px] bg-gray-300 left-0 bottom-0  translate-y-1/2;
}
.date-panel.active h4 {
  @apply text-3xl text-primary;
}
.date-panel.active .date-icon {
  @apply text-primary;
}

.history-panel {
  @apply md:mx-4 min-w-[360px] md:p-10 bg-white rounded-xl w-full grid md:grid-cols-3 grid-cols-1  md:gap-4;
}
.history-content {
  @apply col-span-2;
}
.history-img {
  @apply col-span-1 w-full md:h-80 min-h-60 flex items-center gap-5 justify-center md:border-b-2 border-none border-gray-100;
}
.history-img img {
  @apply w-full h-full object-cover rounded-lg;
}
.history-img img.logo {
  @apply w-44 h-44 object-contain;
}
.history-content {
  @apply col-span-2 md:flex flex-col md:justify-center p-10;
}
.history-title {
  @apply font-semibold text-2xl mb-5;
}
</style>
