<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    fill="currentColor"
    :class="props.class"
    viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
    <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
  </svg>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  class: {
    required: false,
  },
  width: {
    required: false,
    default: 32,
  },
  height: {
    required: false,
    default: 32,
  },
})
</script>

<style></style>
