<template>
  <hero-section />
  <cookie-popup />
  <presentation-section />
  <history-section />
  <mission-section />
  <core-value-section-vue />
  <partenaire-section />
  <news-letter />
</template>

<script setup>
import HeroSection from './sections/HeroSection.vue';
import CookiePopup from './sections/CookiePopup.vue';
import HistorySection from './sections/HistorySection.vue';
import MissionSection from './sections/MissionSection.vue';
import PresentationSection from './sections/PresentationSection.vue';
import CoreValueSectionVue from './sections/CoreValueSection.vue';
import PartenaireSection from './sections/PartenaireSection.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import { useSeoMeta } from '@unhead/vue'

const baseURL = `${process.env.VUE_APP_API_URL}`

useSeoMeta({
  title: "Raph & Co",
  description: "Découvrez Raph and Co, spécialiste du développement des talents d'ingénieurs à Madagascar. Promouvons ensemble l'ingénierie malgache et atteignons vos objectifs de développement.",
  keywords: "Raph and Co, développement des talents d'ingénieurs, ingénierie Madagascar, services entreprises malgaches, expertise en ingénierie, solutions sur mesure, ingénieurs malgaches, promotion de l'ingénierie",
  
  // social meta datas 
  ogTitle: "Raph & Co",
  ogDescription: "Découvrez Raph and Co, spécialiste du développement des talents d'ingénieurs à Madagascar. Promouvons ensemble l'ingénierie malgache et atteignons vos objectifs de développement.",
  ogUrl: baseURL,
  ogImage: baseURL + "/logo_social_picture.png",
  ogImageAlt: "Logo de Raph and Co en fond gris dégradé",
  ogImageHeight: "1667",
  ogImageWidth: "1667",
  ogType: "website",
  ogSiteName: "Raph and Co"
})
</script>