<template>
    <div class="team-presentation-card">
        <div :class="['team-presentation-image', {'rounded-avatar': props.rounded_avatar} ]">
            <img v-if="!props.rounded_avatar" :src="props.avatar" class="team-avatar">
            <img v-if="props.rounded_avatar" :src="props.avatar" class="team-avatar-rounded">
        </div>
        <div class="team-presentation-content">
            <h2 data-aos="fade-left" data-aos-duration="500" data-aos-once="true">{{ props.fullname }}</h2>
            <h3>{{ props.poste_title }}</h3>
            <div v-html="props.presentation"></div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps(['fullname', 'poste_title', 'avatar', 'presentation', 'rounded_avatar'])
</script>

<style scoped>
/* team presentation  */
.team-presentation-card {
    @apply grid md:grid-cols-3 grid-cols-1 bg-white lg:min-h-[440px] min-h-[60vh] rounded-3xl md:shadow-xl shadow
}
.team-presentation-image {
    @apply col-span-1 pointer-events-none bg-gray-200 bg-contain md:rounded-t-none rounded-t-3xl md:rounded-l-3xl md:h-auto h-[40vh] relative order-1
}
.team-presentation-image::after {
    @apply content-[""] bg-white/30 absolute w-[30vh] h-[30vh] md:w-[340px] md:h-[340px] pointer-events-none z-0 bottom-1/2 translate-y-1/2 left-1/2 -translate-x-1/2 rounded-full
}
.team-presentation-image.rounded-avatar::after {
    @apply hidden
}
.team-presentation-content {
    @apply col-span-2 p-8 sm:py-10 sm:px-14 order-2 bg-[url('~@/assets/team-bg.png')] bg-contain text-white  md:rounded-b-none rounded-b-3xl md:rounded-r-3xl relative
}
.team-presentation-content h2 {
    @apply md:text-3xl text-2xl  font-semibold 
}
.team-presentation-content h3 {
    @apply md:text-xl text-lg md:my-4 my-3 font-semibold tracking-wide 
}

.team-avatar {
    @apply w-full z-10 pointer-events-none object-bottom object-contain absolute bottom-0 left-0 md:h-auto h-[60vh] md:rounded-t-none rounded-t-3xl md:rounded-l-3xl
}
.team-presentation-content::after {
    @apply absolute right-5 md:top-8 top-6 sm:right-10 sm:top-5 content-[""] bg-[url('~@/assets/quotes.png')] bg-contain md:h-28 h-16 md:w-28 w-16 bg-center
}
.team-avatar-rounded {
    @apply w-[30vh] h-[30vh] md:w-[310px] md:h-[310px] pointer-events-none object-center absolute bottom-1/2 translate-y-1/2 left-1/2 -translate-x-1/2 rounded-full ring-1 ring-white
}

.team-presentation-image {
    @apply col-span-1 pointer-events-none bg-gray-200 bg-contain md:rounded-t-none rounded-t-3xl md:rounded-l-3xl md:h-auto h-[40vh] relative order-1
}
</style>