import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import App from './App.vue'
import './index.css'
import router from './router'
import VueQuery, {vueQueryGlobalOptions} from '@/plugins/vue-query'
import VueGtag from 'vue-gtag'

const head = createHead()

createApp(App)
    .use(VueQuery, vueQueryGlobalOptions)
    .use(head)
    .use(router)
    .use(VueGtag, {
        config: { 
            id: process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
        },
        pageTrackerScreenviewEnabled: true
      }, router)
    .mount('#app')
