<template>
    <section id="filiales">
        <div class="w-full bg-blue-50">
            <div class="filiale-grid">
                <router-link :to="{ name: 'raph-management' }" class="filiale-card filiale-1">
                    <img class="filiale-image" :src="RaphManagementIMG" alt="">
                    <div class="filiale-content">
                        <h2>Raph Management</h2>
                    </div>
                </router-link>
                <router-link :to="{ name: 'raph-emploi' }" class="filiale-card filiale-2">
                    <img class="filiale-image" :src="RaphEmploiIMG" alt="">
                    <div class="filiale-content">
                        <h2>Raph Emploi</h2>
                    </div>
                </router-link>
                <router-link :to="{ name: 'raph-green-estate' }" class="filiale-card filiale-3">
                    <img class="filiale-image" :src="RaphGreenEstateIMG" alt="">
                    <div class="filiale-content">
                        <h2>Raph Green Estate</h2>
                    </div>
                </router-link>
                <router-link :to="{ name: 'raph-engineering' }" class="filiale-card filiale-4">
                    <img class="filiale-image" :src="RaphEngineeringIMG" alt="">
                    <div class="filiale-content">
                        <h2>Raph Engineering</h2>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script setup>
import RaphManagementIMG from "@/assets/filiales/raph-management-hero.jpg"
import RaphEmploiIMG from "@/assets/filiales/raph-emploi-hero.jpg"
import RaphGreenEstateIMG from "@/assets/filiales/raph-green-estate-hero.jpg"
import RaphEngineeringIMG from "@/assets/filiales/raph-engineering-hero.jpg"
import { useSeoMeta } from '@unhead/vue'

useSeoMeta({
    title: "Nos filiales",
    description: "Explorez nos quatre filiales : Raph Management, Raph Emploi, Raph Green Estate et Raph Engineering, chacune spécialisée dans des domaines spécifiques et de services à Madagascar. Découvrez comment Raph and Co répond aux besoins variés des entreprises malgaches avec des solutions innovantes et une expertise locale.",
    keywords: "Raph and Co filiales, Raph Management, Raph Emploi, Raph Green Estate, Raph Engineering, ingénierie Madagascar, services spécialisés, solutions innovantes, expertise locale"
})
</script>

<style scoped>
.filiale-grid {
    @apply h-auto md:h-[82vh] flex md:flex-row flex-col
}

.filiale-card {
    @apply flex-grow w-full h-[80vh] md:w-1/4 md:hover:w-2/5 ease-in-out duration-700 md:h-full relative overflow-hidden cursor-pointer
}

.filiale-card::after {
    content: "";
    @apply absolute w-full h-full z-20 top-0 left-0 bg-blue-950/50
}

.filiale-content {
    @apply w-full p-5 absolute bottom-10 left-0 z-30
}

.filiale-content h2 {
    @apply text-3xl font-thin uppercase text-white tracking-wide
}

.filiale-image {
    @apply absolute top-0 z-10 left-0 w-full h-full object-cover duration-700
}

.filiale-card:hover .filiale-image {
    @apply scale-110
}
</style>