<template>
    <div class="custom-field">
        <select :class="{ error: props.errors?.[props.name] || props.backendErrors?.[props.name] }" v-model="model" :initialValue="initialValue">
            <option v-for="(option, i) in props.options" :key="i" :value="option.value">{{ option?.label }}</option>
        </select>
        <p class="error" v-if="props.errors?.[props.name]">{{ props.errors?.[props.name] }}</p>
        <p class="error" v-if="props.backendErrors?.[props.name] ?? ''">{{ props.backendErrors?.[props.name][0] }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineModel } from 'vue';
const model = defineModel({ required: true })
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    initialValue: {
        type: [Array, String, null, Object],
        required: false
    },
    options: {
        type: [Array, Object, {
            value: [String, null, Number, Object, Array],
            label: String
        }],
        required: true
    },
    placeholder: {
        type: String,
        required: false
    },
    errors: {
        type: [Array, String, null, Object],
        required: true
    },
    backendErrors: {
        type: [Array, String, null, Object],
        required: true
    }
})
</script>

<style scoped>
.custom-field select {
    @apply block w-full px-6 py-3 focus:outline-none rounded-xl md:rounded-full ring-1 ring-gray-400 focus:ring-2 focus:ring-blue-800 duration-500
}

.custom-field select.error {
    @apply ring-red-400
}

p.error {
    @apply text-sm ml-3 text-red-500
}
</style>