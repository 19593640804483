import { ref } from 'vue'
import axiosClient from '@/utils/axios'
import * as yup from "yup"

const useContact = () => {
  const loading = ref(false)
  const contact = ref(null)
  const errors = ref(null)

  const sendMessage = async (contactDTO) => {
    loading.value = true
    errors.value = null

    try {
      const res = await axiosClient.post('api/contact', contactDTO)
      contact.value = res.data
    } catch (err) {
      if (err.response && err.response.status === 422) {
        errors.value = err.response.data.errors || []
      } else {
        errors.value = err.response ? err.response.statusText : err.message
      }
    } finally {
      loading.value = false
    }
  }

  
const contactSchema = yup.object({
  first_name: yup.string()
    .matches(/^[0-9a-zA-Z]+$/, 'Votre nom ne doit contenir que des lettres et des chiffres.')
    .required("Veuillez entrer votre nom"),
  last_name: yup.string()
    .matches(/^[0-9a-zA-Z]+$/, 'Votre prénom ne doit contenir que des lettres et des chiffres.')
    .required("Veuillez entrer votre prénom"),
  email: yup.string().email("Veuillez entrer un email valide").required("Veuillez entrer votre email"),
  service: yup.number("Service sélectionné invalide").not([0], "Veuillez sélectionner un service").required("Veuillez sélectionner un service"),
  message: yup.string().required("Veuillez entrer votre message")
})

  return {
    loading,
    contact,
    errors,
    sendMessage,
    contactSchema
  }
}

export default useContact
