<template>
    <div class="service-2-card">
        <div v-if="!props.image_right" class="service-2-image image-left">
            <img :src="props.image" :alt="props.title">
        </div>
        <div class="service-2-content">
            <h2>{{ props.title }}</h2>
            <div class="service-2-description" v-html="props.description"></div>
        </div>
        <div v-if="props.image_right" class="service-2-image image-right">
            <img :src="props.image" :alt="props.title">
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps(['title', 'description', 'details', 'image', 'image_right'])
</script>

<style scoped>
.service-2-card {
    @apply grid col-span-1 md:grid-cols-2 md:my-0 my-5
}

.service-2-image {
    @apply min-h-[40vh] 
}
.service-2-image.image-left {
    @apply shadow order-1
 }
.service-2-image.image-right {
    @apply md:shadow-[_0_0_3px_0_rgb(0,0,0,0.1),_1px_0_0_-1px_rgb(0,0,0,0.1)] shadow md:order-3 order-1
 }


.service-2-image img {
    @apply h-[40vh] object-contain w-full
}

.service-2-content {
    @apply bg-white p-8 order-2
}

.service-2-content h2 {
    @apply text-2xl font-semibold text-gray-700 mb-3
}
</style>