<template>
    <div class="flex gap-3 flex-wrap animate-pulse">
        <button class="px-4 h-10 w-32 bg-gray-200 text-gray-700 rounded-full">
        </button>
        <button class="px-4 h-10 w-32 bg-gray-200 text-gray-700 rounded-full">
        </button>
        <button class="px-4 h-10 w-32 bg-gray-200 text-gray-700 rounded-full">
        </button>
        <button class="px-4 h-10 w-32 bg-gray-200 text-gray-700 rounded-full">
        </button>
        <button class="px-4 h-10 w-32 bg-gray-200 text-gray-700 rounded-full">
        </button>
    </div>
</template>