<template>
    <div role="alert" :class="alertClass">
        <div class="shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z">
                </path>
            </svg>
        </div>
        <div class="ml-3 mr-12">{{ props.message }}</div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
const props = defineProps({
    message: {
        type: String,
        required: true
    },
    color: {
        type: [String],
        validator(value) {
            return ['info', 'success', 'warning', 'error'].includes(value)
        },
        required: false,
        default: 'info'
    }
})
const alertClass = computed(() => {
    if(props.color == 'info') return ['alert', 'info']
    if(props.color == 'success') return ['alert', 'success']
    if(props.color == 'warning') return ['alert', 'warning']
    if(props.color == 'error') return ['alert', 'error']
    return ['alert']
})
</script>

<style scoped>
.alert {
    @apply relative flex w-full px-4 py-4 text-base rounded-lg bg-gray-200
}
.alert.info {
    @apply text-primary bg-primary/10
}
.alert.success {
    @apply text-green-600 bg-green-500/10
}
.alert.warning {
    @apply text-orange-600 bg-orange-500/10
}
.alert.error {
    @apply text-red-600 bg-red-500/10
}
</style>