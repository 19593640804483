<template>
  <section id="filiale-raph-emploi">
    <!-- hero  -->
    <hero-profil
      :bg-url="require('@/assets/filiales/raph-emploi-hero-2.jpg')"
      :logo="require('@/assets/filiales/logos/logo-raph-emploi.png')"
      title="Raph Emploi"
      description="Raph Emploi est une entreprise née d’une coalition entre le recrutement classique et
                        moderne.
                        La plateforme reliera les professionnels de chaque secteur d’activité, que ce soit technique ou
                        managériale.
                        Nous ambitionnons de devenir le linkedin d’Afrique moderne. " />

    <!-- stats  -->
    <div class="py-20">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos réalisations</h2>
        <p class="mb-5 md:w-3/5">
          Explorez nos réalisations à travers des données percutantes. Découvrez nos succès chiffrés, reflétant notre
          engagement constant envers l'excellence et la performance.
        </p>

        <div class="stats-grid">
          <div class="stats-card">
            <div class="stats-content">
              <div class="stats-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-person-check"
                  viewBox="0 0 16 16">
                  <path
                    d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                  <path
                    d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                </svg>
              </div>
              <div class="stats-detail">
                <h2>
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="27"
                    :duration="2"
                    separator=" "
                    decimalSeparator="."
                    :decimals="0"
                    :autoinit="true" />
                </h2>
                <p>Clients satifaits</p>
              </div>
            </div>
          </div>
          <div class="stats-card">
            <div class="stats-content">
              <div class="stats-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                  <path
                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                </svg>
              </div>
              <div class="stats-detail">
                <h2>
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="200"
                    :duration="2"
                    separator=" "
                    decimalSeparator="."
                    :decimals="0"
                    :autoinit="true" />+
                </h2>
                <p>Recrutements</p>
              </div>
            </div>
          </div>
          <div class="stats-card">
            <div class="stats-content">
              <div class="stats-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-journal-check"
                  viewBox="0 0 16 16">
                  <path
                    fill-rule="evenodd"
                    d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                  <path
                    d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                  <path
                    d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                </svg>
              </div>
              <div class="stats-detail">
                <h2>
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="8"
                    :duration="2"
                    separator=" "
                    decimalSeparator="."
                    :decimals="0"
                    :autoinit="true" />K+
                </h2>
                <p>CV reçus</p>
              </div>
            </div>
          </div>
          <div class="stats-card">
            <div class="stats-content">
              <div class="stats-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-briefcase" viewBox="0 0 16 16">
                  <path
                    d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
                </svg>
              </div>
              <div class="stats-detail">
                <h2>
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="300"
                    :duration="2"
                    separator=" "
                    decimalSeparator="."
                    :decimals="0"
                    :autoinit="true" />+
                </h2>
                <p>Offres d'emploi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- siteweb cta  -->
    <!-- <official-web-site-cta filiale="Raph Emploi" link="#" /> -->
    <!-- services  -->
    <div class="py-20 bg-gray-50">
      <div class="container">
        <h2 class="text-4xl text-blue-900 font-semibold mb-5">Nos services</h2>
        <p class="mb-5 md:w-3/5">
          Explorez nos services personnalisés, conçus pour répondre à vos besoins uniques. De la consultation à la
          réalisation, nous vous offrons un accompagnement expert à chaque étape de votre projet.
        </p>

        <div class="service-2-grid">
          <service-card2
            v-for="(service, i) in services"
            :key="i"
            :image_right="service.image_right"
            :title="service.title"
            :description="service.description"
            :image="service.image" />
        </div>
      </div>
    </div>

    <!-- equipe  -->
    <div class="py-20">
      <div class="container">
        <team-presentation-card
          fullname="Lanona Raharison"
          poste_title="Hiring Manager"
          :presentation="presentation"
          :avatar="team_lanona" />
      </div>
    </div>

    <!-- navigation   -->
    <div class="pb-10">
      <div class="container">
        <filiale-navigation
          prev_filiale="Raph Management"
          next_filiale="Raph Green Estate"
          prev_pathname="raph-management"
          next_pathname="raph-green-estate" />
      </div>
    </div>
  </section>
</template>

<script setup>
// images
import RE_service_1 from '@/assets/services/RE/service-1.png'
import RE_service_2 from '@/assets/services/RE/service-2.png'
import team_lanona from '@/assets/team/lanona.png'

// components
import Vue3Autocounter from 'vue3-autocounter'
import HeroProfil from '../components/HeroProfil.vue'
import FilialeNavigation from '../components/FilialeNavigation.vue'
import ServiceCard2 from '../components/ServiceCard-2.vue'
import TeamPresentationCard from '../components/TeamPresentationCard.vue'

import { useSeoMeta } from '@unhead/vue'

useSeoMeta({
  title: 'Raph Emploi',
  description:
    'Raph Emploi révolutionne le recrutement en Afrique moderne avec une plateforme innovante. Trouvez les meilleurs talents pour votre entreprise grâce à nos services personnalisés et à notre approche sur mesure.',
  keywords:
    'Raph Emploi, recrutement en ligne, plateforme de recrutement, talents professionnels, emploi personnalisé, linkedin d’Afrique',
})

const services = [
  {
    title: 'Recrutement via la plateforme ',
    description: `
        <p class="mb-4">Le service de recrutement via la plateforme de Raph Emploi révolutionne la façon dont les entreprises trouvent leurs talents. En offrant une plateforme intuitive et efficace, nous simplifions le processus de recrutement pour les recruteurs et les candidats. Grâce à notre technologie avancée, les recruteurs peuvent publier des offres d'emploi, trier les candidatures et planifier des entretiens en quelques clics.</p>
        <p class="mb-4">Pour les candidats, notre plateforme offre une multitude d'opportunités professionnelles dans divers secteurs et niveaux d'expérience. En mettant en avant leurs compétences et leur expérience, ils peuvent postuler en toute simplicité et être mis en relation avec des entreprises qui correspondent à leurs aspirations professionnelles.</p>
        <p class="mb-4">Notre objectif est de créer un environnement où le processus de recrutement est transparent, équitable et efficace pour toutes les parties impliquées. En facilitant la rencontre entre les entreprises et les talents, nous contribuons à la croissance et au succès des entreprises ainsi qu'à l'épanouissement professionnel des individus.</p>
        `,
    image: RE_service_1,
    image_right: false,
  },
  {
    title: 'Recrutement sur mesure ',
    description: `
        <p class="my-4">Ce service de Raph Emploi offre une approche personnalisée pour répondre aux besoins uniques de chaque entreprise. En collaborant étroitement avec nos clients, nous comprenons leurs exigences spécifiques en termes de compétences et de culture d'entreprise.</p>
        <p class="my-4">Notre équipe dédiée utilise des méthodes de sourcing avancées pour identifier et sélectionner les meilleurs talents pour chaque poste. Nous gérons le processus de recrutement de bout en bout, en garantissant une expérience fluide et efficace pour les employeurs et les candidats.</p>
        <p class="my-4">Avec notre approche sur mesure, nous visons à fournir des solutions de recrutement adaptées qui permettent aux entreprises de trouver les candidats les mieux qualifiés pour chaque poste, contribuant ainsi à leur succès à long terme.</p>
        `,
    image: RE_service_2,
    image_right: true,
  },
]

const presentation = `
                    <p class=" mb-4">Raph Emploi est le fruit d'une alliance entre le recrutement traditionnel et moderne. Notre plateforme vise à connecter les professionnels de tous les secteurs, qu'ils soient techniques ou managériaux.</p>
                    <p class="mb-4">Nous aspirons à devenir le LinkedIn de l'Afrique moderne, offrant des opportunités professionnelles innovantes et une connectivité sans frontières pour les talents de demain.</p>
                    <p class="mb-4">Notre vision est de créer un écosystème où chaque individu peut trouver sa place et contribuer au développement économique de la région.</p>
                    `
</script>

<style scoped>
.filiale-hero {
  background-image: url('~@/assets/filiales/raph-emploi-hero-2.jpg');
  @apply relative h-[82vh] flex items-end py-5 bg-cover bg-center z-10;
}

.filiale-hero::after {
  content: '';
  @apply absolute top-0 left-0 w-full h-full bg-blue-950/50 -z-10;
}

.filiale-hero-content {
  @apply max-w-7xl w-full mx-auto px-5 py-8 text-white rounded-lg flex justify-between;
}

.filiale-presentation {
  @apply md:w-2/3 w-full;
}
/* stats  */

.stats-grid {
  @apply grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 md:gap-10 gap-5;
}

.stats-card {
  @apply col-span-1 min-h-60 flex items-end duration-500 hover:-translate-y-3;
}

.stats-content {
  @apply bg-blue-50 w-full relative p-5 rounded-2xl shadow-sm;
}

.stats-detail {
  @apply text-center p-8 flex justify-center flex-col;
}

.stats-detail h2 {
  @apply font-semibold text-5xl mb-2;
}

.stats-detail p {
  @apply text-lg;
}

.stats-icon {
  @apply absolute z-10 top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 h-20 w-20 bg-blue-900 text-white flex justify-center items-center rounded-full ring-8 ring-white;
}

.stats-icon svg {
  @apply w-8 h-8;
}

/* service  */
.service-2-grid {
  @apply grid grid-cols-1;
}
</style>
