// page components
import HomePage from "@/pages/home/HomePage.vue"
import ContactPage from "@/pages/contact/ContactPage.vue"
import FilialesPage from "@/pages/filiales/FilialesPage.vue"
import NewsPage from "@/pages/news/NewsPage.vue"
import SingleNews from "@/pages/news/single/SingleNews.vue"
import RaphEmploiPage from "@/pages/filiales/raph-emploi/RaphEmploiPage.vue"
import RaphEngineeringPage from "@/pages/filiales/raph-engineering/RaphEngineeringPage.vue"
import RaphGreenEstatePage from "@/pages/filiales/raph-green-estate/RaphGreenEstatePage.vue"
import RaphManagementPage from "@/pages/filiales/raph-management/RaphManagementPage.vue"
import FormationsPage from "@/pages/filiales/raph-management/formations/FormationsPage.vue"
import REN_FormationsPage from "@/pages/filiales/raph-engineering/formations/FormationsPage.vue"
import NotFoundPage from "@/pages/errors/NotFoundPage.vue"
import OnBuildingPage from "@/pages/errors/OnBuildingPage.vue"

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
    },
    {
        path: '/qui-sommes-nous',
        name: 'about-us',
        component: HomePage
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage
    },
    {
        path: '/filiales',
        name: 'filiales',
        component: FilialesPage,
    },
    {
        path: '/filiales/raph-emploi',
        name: 'raph-emploi',
        component: RaphEmploiPage,
    },
    {
        path: '/filiales/raph-engineering',
        name: 'raph-engineering',
        component: RaphEngineeringPage,
    },
    {
        path: '/filiales/raph-engineering/formations',
        name: 'raph-engineering-formations',
        component: REN_FormationsPage,
    },
    {
        path: '/filiales/raph-green-estate',
        name: 'raph-green-estate',
        component: RaphGreenEstatePage,
    },
    {
        path: '/filiales/raph-management',
        name: 'raph-management',
        component: RaphManagementPage,
    },
    {
        path: '/filiales/raph-management/formations',
        name: 'raph-management-formations',
        component: FormationsPage,
    },
    {
        path: '/actualites',
        name: 'news',
        component: NewsPage
      },
    {
        path: '/actualites/:slug',
        name: 'single-news',
        component: SingleNews
    },
    {
        path: '/politique-de-confidentialite',
        name: 'politique-de-confidentialite',
        component: OnBuildingPage
    },
    {
        path: '/mentions-legales',
        name: 'mentions-legales',
        component: OnBuildingPage
    },
    {
        path: '/management',
        name: 'management',
        component: OnBuildingPage
    },
    {
        path: '/rejoignez-nous',
        name: 'rejoignez-nous',
        component: OnBuildingPage
    },
    {
        path: '/testimoniales',
        name: 'testimoniales',
        component: OnBuildingPage
    },
    {
        path: '/journal-ingenieur',
        name: 'journal-ingenieur',
        component: OnBuildingPage
    },
    { 
        path: '/:pathMatch(.*)*', 
        name: 'NotFound', 
        component: NotFoundPage 
    },

]

export default routes