<template>
  <div class="news-card">
    <div class="news-image">
      <router-link :to="{ name: 'single-news', params: { slug: props.news.slug } }">
        <img :src="props.news.thumbnailURL" alt="news image" />
      </router-link>
    </div>
    <div class="news-content">
      <h2>
        <router-link :to="{ name: 'single-news', params: { slug: props.news.slug } }">{{ props.news.title }}</router-link>
      </h2>
      <p>{{ props.news.excerpt }}</p>
    </div>
    <div class="news-footer">
      <div class="author-avatar">
        <img :src="team_mahery" alt="avatar" />
        <span>{{ props.news.author }}</span>
      </div>
      <p class="capitalize">{{ date }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import team_mahery from '@/assets/team/mahery.png'
import dateToFrench from '@/utils/dateFrenchFormat'
const props = defineProps({
  news: {
    id: String,
    title: String,
    slug: String,
    excerpt: String,
    content: String,
    author: String,
    thumbnailURL: String,
    created_at: String,
    updated_at: String,
  },
})
const date = dateToFrench(props.news.created_at)
</script>

<style scoped>
.news-card {
  @apply rounded-3xl flex flex-col justify-between shadow bg-white;
}
.news-image {
  @apply w-full h-[40vh] lg:max-h-[310px] rounded-t-3xl bg-gray-100 overflow-hidden;
}

.news-image img {
  @apply w-full h-full object-cover object-center brightness-90 duration-700 ease-in-out;
}
.news-image:hover img {
  @apply rotate-12 scale-125 brightness-100;
}

.news-content {
  @apply p-5;
}

.news-content h2 {
  @apply font-bold text-gray-600 text-xl mb-3;
}

.news-footer {
  @apply p-5 flex justify-between gap-3 items-center;
}
.news-footer p {
  @apply text-gray-500 text-sm font-semibold tracking-wide;
}

.author-avatar {
  @apply flex gap-3 items-center;
}
.author-avatar span {
  @apply font-semibold text-gray-700;
}
.author-avatar img {
  @apply w-14 h-14 bg-gray-200 rounded-full object-contain pointer-events-none;
}
</style>
