import axiosClient from '@/utils/axios'
import { useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'

const useFiltersData = () => {
  const errors = ref(null)
  const queryKey = ref('filters')

  const getFilters = async () => {
    errors.value = null
    try {
      const res = await axiosClient.get(`api/actualites/filters`)
      return res.data
    } catch (err) {
      catchErrors(err)
      throw new Error(err.response ? err.response.data.message : err.message)
    }
  }

  // INDEX
  const { data, error, isPending, isFetching, isError, refetch } = useQuery({
    queryKey: [queryKey.value],
    queryFn: () => getFilters(),
    refetchOnMount: false,
    staleTime: 100,
    retry: false,
  })

  // ERROR HANDLER
  const catchErrors = (err) => {
    if (err.response && err.response.status === 422) {
      errors.value = err.response.data.errors || []
    } else if (err.response && err.response.status === 400) {
      errors.value = { message: err.response.data.errors || '' }
    } else if (err.response && err.response.status === 403) {
      errors.value = { message: err.response.data.error || '' }
    } else {
      errors.value = err.response ? err.response.statusText : err.message
    }
  }

  return { data, error, isPending, isFetching, isError, refetch }
}

export default useFiltersData
