<template>
    <section>
        <div class="container text-center flex flex-col justify-center">
            <h2 class="my-5 font-bold text-gray-600 text-xl">Raph Management vous propose diverses formations</h2>
            <div class="flex items-center w-max mx-auto">
                <button class="prev-btn" @click="page = page > 1 ? page - 1 : page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                    </svg>
                </button>
                <span class="text-lg"> {{ page }} / {{ pages }} </span>
                <button class="next-btn" @click="page = page < pages ? page + 1 : page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </button>
            </div>
            <div class="overflow-auto p-4" id="pdf-container">
                <div
                    class="ring-1 flex items-center justify-center flex-col gap-2 rounded-3xl my-10 ring-blue-900 p-5 w-max mx-auto hover:cursor-grab active:cursor-grabbing">
                    <div v-if="isLoading" class="w-40 h-40 flex items-center justify-center flex-col gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="h-12 w-12 animate-spin text-blue-900" viewBox="0 0 16 16">
                            <path
                                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                            <path fill-rule="evenodd"
                                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                        </svg>
                        <p>Chargement...</p>
                    </div>
                    <VuePDF :scale="2.1" :pdf="pdf" :page="page" @loaded="onLoaded" />
                </div>
            </div>
            <div class="flex items-center w-max mx-auto my-5">
                <button class="prev-btn" @click="page = page > 1 ? page - 1 : page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                    </svg>
                </button>
                <span class="text-lg"> {{ page }} / {{ pages }} </span>
                <button class="next-btn" @click="page = page < pages ? page + 1 : page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="py-10 bg-gradient-to-r from-gray-100 to-gray-200">
            <div class="container flex flex-col justify-center items-center">
                <router-link class="btn-retour" :to="{ name: 'raph-management' }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                        class="bi bi-arrow-left icon duration-300" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                    </svg>
                    <span>Raph Management</span>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

const isLoading = ref(true)
const page = ref(1)
const { pdf, pages } = usePDF('/pdf/RM catalogue.pdf')
const onLoaded = () => {
    isLoading.value = false
}
onMounted(() => {
    const container = document.querySelector('#pdf-container');

    let startY;
    let startX;
    let scrollLeft;
    let scrollTop;
    let isDown;

    container.addEventListener('mousedown', e => mouseIsDown(e));
    container.addEventListener('mouseup', e => mouseUp(e))
    container.addEventListener('mouseleave', e => mouseLeave(e));
    container.addEventListener('mousemove', e => mouseMove(e));

    function mouseIsDown(e) {
        isDown = true;
        startY = e.pageY - container.offsetTop;
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
        scrollTop = container.scrollTop;
    }
    function mouseUp() {
        isDown = false;
    }
    function mouseLeave() {
        isDown = false;
    }
    function mouseMove(e) {
        if (isDown) {
            e.preventDefault();
            //Move vertcally
            const y = e.pageY - container.offsetTop;
            const walkY = y - startY;
            container.scrollTop = scrollTop - walkY;

            //Move Horizontally
            const x = e.pageX - container.offsetLeft;
            const walkX = x - startX;
            container.scrollLeft = scrollLeft - walkX;

        }
    }
})
</script>

<style scoped>
.next-btn,
.prev-btn {
    @apply px-8 py-2.5 mx-4 rounded-full bg-blue-900 text-white duration-300 hover:bg-blue-800
}

.btn-retour {
    @apply px-8 py-4 border-2 border-blue-900 text-blue-900 inline-flex items-center gap-3 w-max rounded-full duration-500 ease-in-out hover:bg-white/50
}

.btn-retour:hover .icon {
    @apply -translate-x-2
}

/* width */
#pdf-container::-webkit-scrollbar {
    @apply h-1 rounded-3xl
}

/* Track */
#pdf-container::-webkit-scrollbar-track {
    @apply bg-blue-800/10 rounded-full
}

/* Handle */
#pdf-container::-webkit-scrollbar-thumb {
    @apply bg-blue-900 rounded-full duration-300 ease-in-out hover:cursor-grab active:cursor-grabbing
}

/* Handle on hover */
#pdf-container::-webkit-scrollbar-thumb:hover {
    @apply bg-blue-800
}
</style>