<template>
    <nav class="menu bg-white shadow">
        <!-- Main menu  -->
        <div class="container hidden md:flex items-center justify-between">
            <a class="nav-link" href="/#company-presentation">Qui sommes nous</a>
            <router-link class="nav-link" :to="{name: 'filiales'}">Filiales</router-link>
            <router-link class="nav-logo" :to="{name: 'home'}">
                <img class="max-w-60" :src="LogoUrl" alt="Raph and Co logo">
            </router-link>
            <router-link class="nav-link" :to="{name: 'news'}">Actualites</router-link>
            <router-link class="nav-link" :to="{name: 'contact'}">Contact</router-link>
        </div>

        <!-- Reponsive menu -->
        <div class="container md:hidden flex items-center justify-between relative">
            <router-link class="nav-logo" :to="{name: 'home'}">
                <img class="max-w-52" :src="LogoUrl" alt="Raph and Co logo">
            </router-link>
            <button @click="switchMobileMenu">
                <svg v-if="!MobileMenuOpen"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-8 w-8"  viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                </svg>
            </button>

            <!-- Mobile menu  -->
            <div v-if="MobileMenuOpen" class="absolute top-full left-0 w-full h-auto overflow-hidden">
                <div class="border-y-2">
                    <a class="mobile-nav-link" href="/#company-presentation">Qui sommes nous</a>
                    <router-link @click="switchMobileMenu" class="mobile-nav-link" :to="{name: 'filiales'}">Filiales</router-link>
                    <router-link @click="switchMobileMenu" class="mobile-nav-link" :to="{name: 'news'}">Actualités</router-link>
                    <router-link @click="switchMobileMenu" class="mobile-nav-link" :to="{name: 'contact'}">Contact</router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import LogoUrl from "@/assets/Logo Raph & co.png"
import { ref } from "vue";

const MobileMenuOpen = ref(false)
const switchMobileMenu = () => MobileMenuOpen.value = !MobileMenuOpen.value

</script>

<style>
.menu a.nav-link, .menu a.nav-logo {
    @apply hover:text-blue-800 uppercase text-xs font-semibold tracking-wider relative
}
.menu a.nav-link {
    @apply p-3 duration-500
}
.menu a.nav-link::after {
    content: "";
    @apply top-[90%] left-0 h-[3px] w-0 bg-blue-800 absolute duration-500 rounded
}
.menu a.nav-link:hover::after {
    content: "";
    @apply w-full
}

.menu .mobile-nav-link {
    @apply block w-full bg-white p-4 relative z-20 hover:text-white duration-500
}

.menu .mobile-nav-link::after {
    content: "";
    @apply absolute top-0 left-0 h-full w-0 -z-20 bg-blue-800 duration-500
}

.menu .mobile-nav-link:hover::after {
    @apply w-full
}
</style>