<template>
  <section id="newsletter">
    <div class="container">
      <h2 class="text-4xl font-semibold text-blue-900">Rejoignez notre newsletter</h2>
      <p class="my-6 tracking-widest text-lg">Ne manquez rien</p>
      <div class="newsletter-form">
        <form @submit.prevent="onSubmit" class="flex flex-col md:flex-row gap-5">
          <div class="md:w-1/5 w-full" data-aos-once="true" data-aos="zoom-in-up">
            <input v-model="name" :class="['w-full', { error: errors.name || backendErrors?.name }]" type="text"
              placeholder="Votre nom">
            <p v-if="errors?.name" data-aos="zoom-in" data-aos-once="true" class="text-red-500 text-sm mt-2 ml-3">{{
              errors.name }}</p>
            <p v-if="backendErrors?.name ?? ''" data-aos="zoom-in" data-aos-once="true"
              class="text-red-500 text-sm mt-2 ml-3">{{ backendErrors?.name[0] }}</p>
          </div>
          <div class="md:w-2/5 w-full" data-aos-once="true" data-aos="zoom-in-up">
            <input v-model="email" :class="['w-full', { error: errors.email || backendErrors?.email }]"
              data-aos-delay="100" type="email" placeholder="Votre email">
            <p v-if="errors?.email" data-aos="zoom-in" data-aos-once="true" class="text-red-500 text-sm mt-2 ml-3">{{
              errors.email }}</p>
            <p v-if="backendErrors?.email ?? ''" data-aos="zoom-in" data-aos-once="true"
              class="text-red-500 text-sm mt-2 ml-3">{{ backendErrors?.email[0] }}</p>
          </div>
          <button data-aos-once="true" :disabled="loading" data-aos="zoom-in-up" data-aos-delay="200" type="submit"
            class="btn-newsletter">
            {{ loading ? "Abonnement..." : "S'abonner" }}
          </button>
        </form>
        <p v-if="message" @click="message = ''" data-aos="fade-left" data-aos-once="true"
          class="text-green-500 mt-4 tracking-wider cursor-default">{{ message }}</p>
        <p v-if="backendErrors && !backendErrors?.message" data-aos="fade-left" data-aos-once="true"
          class="text-red-500 mt-4 tracking-wider cursor-default">Abonnement échoué. Veuillez réessayer ultérieurement.
        </p>
        <p v-if="backendErrors && backendErrors?.message" data-aos="fade-left" data-aos-once="true"
          class="text-blue-500 mt-4 tracking-wider cursor-default">{{ backendErrors?.message }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from "yup"
import axiosClient from '@/utils/axios';

const loading = ref(false)
const success = ref(false)
const backendErrors = ref(false)

const subscribe = async (subscriber) => {
  loading.value = true
  backendErrors.value = null

  try {
    await axiosClient.post('api/news-letter', subscriber)
  } catch (err) {
    if (err.response && err.response.status === 422) {
      backendErrors.value = err.response.data.errors || []
    } if (err.response && err.response.status === 400) {
      backendErrors.value = err.response.data || null
    } else {
      backendErrors.value = err.response ? err.response.statusText : err.message
    }
  } finally {
    loading.value = false
  }
}

const schema = yup.object({
  name: yup.string()
    .matches(/^[0-9a-zA-Z]+$/, 'Votre nom ne doit contenir que des lettres et des chiffres sans espaces')
    .required("Veuillez entrer votre nom"),
  email: yup.string().email("Veuillez entrer un email valide").required("Veuillez entrer votre email"),
})

const { defineField, errors, handleSubmit, resetForm } = useForm({
  validationSchema: schema,
})

const [name] = defineField('name')
const [email] = defineField('email')

const message = ref('')

const onSuccessValidation = async (values) => {
  // submit code
  success.value = false
  message.value = ""

  // backend call
  await subscribe(values)
  if (!backendErrors.value) {
    success.value = true
    message.value = "Merci de votre abonnement !"
    resetForm()
  }
  if (backendErrors.value?.message) resetForm()

}
const onValidationFailed = () => { success.value = false; message.value = ""; }

const onSubmit = handleSubmit(onSuccessValidation, onValidationFailed)

</script>

<style scoped>
#newsletter {
  /* background-image: url('~@/assets/bg-core-value-section.jpg'); */
  background-image: url('~@/assets/bg-newsletter-section.jpg');
  @apply bg-blue-50 py-10 bg-fixed bg-cover bg-center
}

.newsletter-form {
  @apply max-w-5xl
}

.newsletter-form input {
  @apply px-6 py-3 focus:outline-none shadow rounded-full inline-block focus:ring-2 focus:ring-blue-800 duration-500
}

.newsletter-form input.error {
  @apply ring-2 focus:ring-red-400 ring-red-400
}

.btn-newsletter {
  @apply inline-flex h-max items-center justify-center font-semibold tracking-widest px-6 py-3 bg-blue-900 text-white rounded-full hover:bg-blue-800 duration-300 hover:-translate-y-1
}
</style>